import * as Models from 'disco-music-api';

import Label, { LabelSize } from '../../typography/Label';
import Icon, { IconName } from '../../foundation/Icon';
import IconSVG, { SVGName, SVGSize } from '../../foundation/IconSVG';

import styles from './index.module.scss';
import { MusicPlayer } from '../../../context/player';
import { TrackList } from '../../TrackList';
import React, { useState } from 'react';

export interface PlayingNextProps {
  player: MusicPlayer | null;
}
export default function PlayingNext(props: PlayingNextProps) {
  const { player } = props;
  const tracks =
    player?.queues.MAIN.tracks.reduce<{
      [id: string]: Models.Track.Track;
    }>((accum, track) => ({ ...accum, [track.id]: track }), {}) || {};
  const history =
    player?.history.reduce<{
      [id: string]: Models.Track.Track;
    }>((accum, track) => ({ ...accum, [track.id]: track }), {}) || {};

  const [showHistory, setShowHistory] = useState(false);

  return (
    <div className={styles.PlayingNext}>
      {showHistory && (
        <React.Fragment>
          <Header
            label={'History'}
            showHistory={showHistory}
            showHistoryToggle={showHistory}
            showPlaybackControls={showHistory}
            toggleHistory={() =>
              setShowHistory(previousValue => !previousValue)
            }
          />
          <TrackList tracks={history} />
        </React.Fragment>
      )}

      <Header
        label={player?.queues.MAIN.queue?.name || 'Playing Next'}
        subLabel={player?.queues.MAIN.queue?.source || 'Unknown'}
        showHistory={showHistory}
        showHistoryToggle={!showHistory}
        showPlaybackControls={!showHistory}
        toggleHistory={() => setShowHistory(previousValue => !previousValue)}
      />
      <TrackList tracks={tracks} />
    </div>
  );
}

interface HeaderProps {
  label: string;
  subLabel?: string;
  showHistory: boolean;
  showHistoryToggle: boolean;
  showPlaybackControls: boolean;
  toggleHistory: () => void;
}
function Header({
  label,
  subLabel,
  showHistory,
  showHistoryToggle,
  showPlaybackControls,
  toggleHistory,
}: HeaderProps) {
  return (
    <div className={styles.Header}>
      <div className={styles.Labels}>
        <div>
          <Label
            value={label}
            size={LabelSize.Small}
            className={styles.Label}
          />
          {showHistoryToggle && (
            <IconSVG
              name={
                showHistory
                  ? SVGName.CollapsibleOpen
                  : SVGName.CollapsibleClosed
              }
              size={SVGSize.Small}
              className={styles.ShowHistoryButton}
              handleClick={toggleHistory}
            />
          )}
        </div>
        {!!subLabel && (
          <Label
            value={subLabel}
            size={LabelSize.ExtraSmall}
            className={styles.Label}
          />
        )}
      </div>
      {showPlaybackControls && <PlaybackControls />}
    </div>
  );
}

interface PlaybackControlsProps {}
function PlaybackControls(_props: PlaybackControlsProps) {
  return (
    <div className={styles.Controls}>
      {/* <div className={styles.ControlSelected}> */}
      <IconSVG
        name={SVGName.PlayerShuffle}
        size={SVGSize.Medium}
        // gradient={player.}
        // className={styles.HeaderIconSVG}
        // disabled={!isPartyHost}
      />
      {/* </div> */}
      <IconSVG
        name={SVGName.PlayerRepeatAll}
        size={SVGSize.Medium}
        // gradient
        // className={styles.HeaderIconSVG}
        // disabled={!isPartyHost}
      />
    </div>
  );
}
