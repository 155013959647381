import React, { useState, useRef } from 'react';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import DiscoMusicAPI from 'disco-music-api';
import * as Models from 'disco-music-api';

import {
  usePrimaryNavigationControl,
  PrimaryNavigationControl,
} from '../../context/primaryNavigationControl';

import Header from './Header';
import NowPlaying from './NowPlaying';
import PlayingNext from './PlayingNext';

import 'react-spring-bottom-sheet/dist/style.css';
import styles from './index.module.scss';
import { usePlayer } from '../../context/player';

interface Props {}
export default function Player(props: Props) {
  usePlayer();
  const [player] = usePlayer();
  const { activeControl, setActiveControl } = usePrimaryNavigationControl();
  // const [isParty, setIsParty] = useState<boolean>(false);

  /* Smaller than phablet (also point before grid becomes active) */
  const isMobile = useMediaQuery({
    query: '(max-width: 550px)',
  });

  const isActive = activeControl === PrimaryNavigationControl.Player;

  const toRender = (sheetRef?: React.RefObject<BottomSheetRef>) => (
    <div className={`${styles.four} ${styles.columns} ${styles.Player}`}>
      <Header
        isParty={!!player?.partyId}
        toggleIsParty={async () => {
          if (!player?.partyId || player?.isPartyHost) {
            await player?.toggleParty();
          } else {
            await player?.leaveParty();
          }
        }}
        bottomSheetRef={sheetRef}
        highlightActiveEnabled={isActive}
        setActiveMenu={() => setActiveControl(PrimaryNavigationControl.Player)}
      />
      <Innards />
    </div>
  );
  return isMobile ? <MobileWrapper>{toRender}</MobileWrapper> : toRender();
}

interface InnardsProps {}
function Innards(props: InnardsProps) {
  const [musicPlayer, serviceStatuses] = usePlayer();
  const history = useHistory();

  const renderInactive = (
    <div className={styles.InactivePlayer}>
      <h4>Welcome!</h4>
      <h5>
        To get started, authenticate a music streaming service with DisCo Music.
      </h5>
      <button onClick={() => history.push('/settings')}>Link</button>
    </div>
  );

  if (!musicPlayer || !musicPlayer.source) {
    return renderInactive;
  }
  const serviceStatus = musicPlayer.partySource
    ? Models.Synthetic.Services.ServiceStatus.Active
    : musicPlayer.source === Models.Synthetic.Services.Service.Spotify
    ? serviceStatuses.SPOTIFY
    : musicPlayer.isAuthorized(Models.Synthetic.Services.Service.AppleMusic)
    ? Models.Synthetic.Services.ServiceStatus.Active
    : serviceStatuses.APPLE_MUSIC;

  switch (serviceStatus) {
    case Models.Synthetic.Services.ServiceStatus.Inactive:
      return renderInactive;
    case Models.Synthetic.Services.ServiceStatus.SessionExpired:
      return (
        <div className={styles.InactivePlayer}>
          <h4>Uh Oh!</h4>
          <h5>
            It looks like we lost access to one or more of your linked services.
            Please refresh them to continue normal use.
          </h5>
          <button onClick={() => history.push('/settings')}>Refresh</button>
        </div>
      );
    case Models.Synthetic.Services.ServiceStatus.Active:
      if (!musicPlayer)
        return (
          <div className={styles.InactivePlayer}>
            <h4>Oops!</h4>
            <h5>
              Looks like you've reached an unknown state and are missing a
              player.
            </h5>
          </div>
        );
      return (
        <>
          <NowPlaying player={musicPlayer} />
          <PlayingNext player={musicPlayer} />
        </>
      );
  }
}

interface MobileWrapperProps {
  children: (sheetRef: React.RefObject<BottomSheetRef>) => JSX.Element;
}
function MobileWrapper(props: MobileWrapperProps) {
  const { children } = props;
  const sheetRef = useRef<BottomSheetRef>(null);

  return (
    <BottomSheet
      open
      skipInitialTransition
      expandOnContentDrag
      scrollLocking={false}
      ref={sheetRef}
      blocking={false}
      defaultSnap={({ maxHeight }) => maxHeight / 6}
      snapPoints={({ maxHeight }) => [maxHeight, maxHeight / 8.5]}
    >
      {children(sheetRef)}
    </BottomSheet>
  );
}
