import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import { useHistory } from 'react-router-dom';
import { BottomSheetRef } from 'react-spring-bottom-sheet';
import * as Models from 'disco-music-api';

import Label, { LabelSize } from '../../typography/Label';
import Button from '../../foundation/Button';
import Icon, { IconName, IconSize } from '../../foundation/Icon';
import IconSVG, { SVGName, SVGSize } from '../../foundation/IconSVG';

import styles from './index.module.scss';
import DevicesModal from './DevicesModal';
import Modal from '../../foundation/Modal';
import { usePlayer } from '../../../context/player';
import ServicesModal from './ServicesModal';
import { Gutter } from '../../Gutter';

interface HeaderProps {
  isParty: boolean;
  toggleIsParty: () => void;
  bottomSheetRef?: React.RefObject<BottomSheetRef>;
  highlightActiveEnabled: boolean;
  setActiveMenu: () => void;
}
export default function Header(props: HeaderProps) {
  const {
    isParty,
    toggleIsParty,
    bottomSheetRef,
    highlightActiveEnabled,
    setActiveMenu,
  } = props;

  const [player] = usePlayer();
  const [isServicesManagerOpen, setIsServicesManagerOpen] = useState<boolean>(
    false
  );
  const [showDevicesModal, setShowDevicesModal] = useState<boolean>(false);
  const [
    isViewMembersCollapseOpen,
    setIsViewMembersCollapseOpen,
  ] = useState<boolean>(false);
  const history = useHistory();

  let modeLabelText = 'Player';

  if (isParty) {
    modeLabelText = 'Party';
  }

  return (
    <>
      {isServicesManagerOpen && (
        <ServicesModal onToggle={async () => setIsServicesManagerOpen(false)} />
      )}
      <div className={styles.ModeSection}>
        <div className={styles.ModeLabelWrapper}>
          {isParty && (
            <IconSVG
              name={
                isViewMembersCollapseOpen
                  ? SVGName.CollapsibleOpen
                  : SVGName.CollapsibleClosed
              }
              size={SVGSize.Medium}
              className={styles.ModeViewPartyMembersButton}
              handleClick={() =>
                setIsViewMembersCollapseOpen(!isViewMembersCollapseOpen)
              }
              gradient
            />
          )}
          <Label
            value={modeLabelText}
            size={LabelSize.Medium}
            className={styles.ModeLabel}
          />
        </div>

        <div>
          <IconSVG
            name={SVGName.Speaker}
            size={SVGSize.Small}
            className={styles.Icon}
            gradient={showDevicesModal}
            handleClick={() => setShowDevicesModal(!showDevicesModal)}
          />

          <IconSVG
            name={SVGName.Choices}
            size={SVGSize.Small}
            className={styles.Icon}
            gradient={isServicesManagerOpen}
            handleClick={() => setIsServicesManagerOpen(!isServicesManagerOpen)}
          />

          <IconSVG
            name={SVGName.SidebarParties}
            size={SVGSize.Small}
            className={styles.Icon}
            gradient={isParty}
            handleClick={toggleIsParty}
          />
        </div>
      </div>

      {isParty && (
        /* BUG: for some reason this animation doesn't actually fire... */
        <div className={styles.Gutter}>
          <Collapse isOpened={isViewMembersCollapseOpen}>
            <div>
              <IconSVG
                name={SVGName.Settings}
                size={SVGSize.Medium}
                handleClick={() => {
                  if (bottomSheetRef && bottomSheetRef.current) {
                    bottomSheetRef.current.snapTo(({ snapPoints }) =>
                      Math.min(...snapPoints)
                    );
                  }
                  setActiveMenu();
                  history.push('/parties/active/settings');
                }}
                className={styles.Settings}
                gradient={highlightActiveEnabled}
              />
            </div>
            <Gutter>
              {player?.partyMembers.map((user, index) => (
                <div
                  key={`party_member_icon_${index}`}
                  aria-label={user.username}
                  role="img"
                  className={styles.UserIcon}
                >
                  <div>{user.username.charAt(0)}</div>
                </div>
              ))}
            </Gutter>
            {/* <div className={styles.PartyMemberListWrapper}>
              <div className={styles.PartyMemberList}>
              </div>
            </div> */}
          </Collapse>
        </div>
      )}
      {showDevicesModal && (
        <DevicesModal onToggle={() => setShowDevicesModal(false)} />
      )}
    </>
  );
}
