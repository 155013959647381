import { useEffect } from 'react';
import { useParams } from 'react-router';

import styles from './index.module.scss';

interface URLParams {
  id: string;
}
interface Props {}

export default function ArtistPage(props: Props) {
  const { id } = useParams<URLParams>();

  useEffect(() => {}, [id]);

  return (
    <div>
      <h1>Artist Details</h1>
    </div>
  );
}
