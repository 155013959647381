import { useState, useEffect } from 'react';
import * as Models from 'disco-music-api';
// import { Track } from '../context/player';

// hook that manages a Spotify Connect SDK client given an access token
export default function useAppleMusicClient(
  developerToken: string | null
):
  | [
      MusicKit.MusicKitInstance,
      {
        // isPaused: boolean;
        isActive: boolean;
        currentTrack: Models.Track.Track | null;
        playingNextTracks: Models.Track.Track[];
      }
    ]
  | null {
  const [player, setPlayer] = useState<MusicKit.MusicKitInstance | null>(null);

  // const [isPaused, setPaused] = useState(true);
  const [isActive, setActive] = useState(false);
  const [currentTrack, setTrack] = useState<Models.Track.Track | null>(null);
  const [playingNextTracks, setPlayingNextTracks] = useState<
    Models.Track.Track[]
  >([]);

  useEffect(() => {
    if (!developerToken) return;

    const script = document.createElement('script');
    script.src = 'https://js-cdn.music.apple.com/musickit/v1/musickit.js';
    // script.src = '/musickit.js';
    script.async = true;

    document.body.appendChild(script);

    // listen for MusicKit Loaded callback
    document.addEventListener('musickitloaded', async () => {
      // MusicKit global is now defined
      /***
      Configure our MusicKit instance with the signed token from server, returns a configured MusicKit Instance
      https://developer.apple.com/documentation/musickitjs/musickit/musickitinstance
    ***/
      MusicKit.configure({
        developerToken,
        app: {
          name: 'DisCo Music',
          build: '1978.4.1',
        },
        declarativeMarkup: false,
      });

      // document.addEventListener('musickit_toggle_play', () => {
      //   console.log('event received: musickit_toggle_play');
      //   setPaused(previousValue => !previousValue);
      // });

      // document.addEventListener(
      //   'musickit_play_track',
      //   (
      //     event: CustomEventInit<{
      //       track: Models.Track.Track;
      //       queue: Models.Track.Track[];
      //     }>
      //   ) => {
      //     console.log('event received: musickit_play_track:', event.detail);
      //     const { track } = event.detail || { track: null, queue: [] };
      //     setTrack(track);
      //     // const p = MusicKit.getInstance();
      //     // console.log(
      //     //   'client.currentPlaybackDuration:',
      //     //   p.player.currentPlaybackDuration
      //     // );
      //     // console.log(
      //     //   'client.currentPlaybackProgress:',
      //     //   p.player.currentPlaybackProgress
      //     // );
      //     // console.log(
      //     //   'client.currentPlaybackTime:',
      //     //   p.player.currentPlaybackTime
      //     // );
      //     // console.log(
      //     //   'client.currentPlaybackTimeRemaining:',
      //     //   p.player.currentPlaybackTimeRemaining
      //     // );
      //     // console.log(
      //     //   'client.formattedCurrentPlaybackDuration:',
      //     //   p.player.formattedCurrentPlaybackDuration
      //     // );
      //   }
      // );
      // music.addEventListener('playback_state_did_change', ((event: any) => {
      //   console.log('playback_state_did_change fireds:', event);
      // }) as any);

      /***
        Add an item to the playback queue
        https://developer.apple.com/documentation/musickitjs/musickit/musickitinstance/2992716-setqueue
      ***/
      // music.setQueue({
      //   items: [],
      //   song: '203709340',
      // });

      /***
        Resume or start playback of media item
        https://developer.apple.com/documentation/musickitjs/musickit/musickitinstance/2992709-play
      ***/
      // music.play();

      /***
        Pause playback of media item
        https://developer.apple.com/documentation/musickitjs/musickit/musickitinstance/2992708-pause
      ***/
      // music.pause();

      /***
        Returns a promise which resolves with a music-user-token when a user successfully authenticates and authorizes
        https://developer.apple.com/documentation/musickitjs/musickit/musickitinstance/2992701-authorize
      ***/
      // music.authorize().then(musicUserToken => {
      //   console.log(`Authorized, music-user-token: ${musicUserToken}`);
      // });

      // expose our instance globally for testing
      setPlayer(MusicKit.getInstance());
      // music.player.
    });
  }, [developerToken]);
  // player?.player.repeatMode
  // player?.player.shuffleMode
  // Return the user object and auth methods
  return player
    ? [player, { isActive, currentTrack, playingNextTracks }]
    : null;
}
