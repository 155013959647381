export default function MusicPlayerPause() {
  return (
    <svg viewBox="0 0 512 512">
      <path
        fillRule="nonzero"
        d="M256 0c70.68 0 134.7 28.66 181.02 74.98C483.34 121.3 512 185.32 512 256s-28.66 134.69-74.98 181.01C390.7 483.33 326.68 511.99 256 511.99s-134.7-28.66-181.02-74.98C28.66 390.69 0 326.68 0 256c0-70.68 28.66-134.7 74.98-181.02C121.3 28.66 185.32 0 256 0zm-66.92 168.32h38.04c5.09 0 9.24 4.21 9.24 9.24v156.87c0 5.03-4.19 9.23-9.24 9.23h-38.04c-5.03 0-9.23-4.16-9.23-9.23V177.56c0-5.09 4.15-9.24 9.23-9.24zm95.77 0h38.06c5.08 0 9.23 4.2 9.23 9.24v156.87c0 5.03-4.19 9.23-9.23 9.23h-38.06c-5.04 0-9.23-4.16-9.23-9.23V177.56c0-5.09 4.16-9.24 9.23-9.24zm127.9-69.07C372.64 59.15 317.21 34.33 256 34.33c-61.21 0-116.64 24.82-156.75 64.92-40.1 40.11-64.92 95.54-64.92 156.75 0 61.21 24.82 116.63 64.92 156.74 40.11 40.1 95.54 64.92 156.75 64.92 61.21 0 116.64-24.82 156.75-64.92 40.1-40.11 64.92-95.53 64.92-156.74 0-61.21-24.82-116.64-64.92-156.75z"
      />
    </svg>
  );
}
