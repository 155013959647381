import React from 'react';

import styles from './index.module.scss';

interface Props {
  children: JSX.Element;
  isOpen?: boolean; // deprecated
  onToggle: () => Promise<void>;
}

export default function Modal(props: Props) {
  const { children, onToggle } = props;
  return (
    <>
      <div className={`${styles.Modal}`}>{children}</div>
      <div className={styles.Backdrop} onClick={onToggle}></div>
    </>
  );
}
