import Label, { LabelSize } from '../../../components/typography/Label';
import Icon, { IconName } from '../../../components/foundation/Icon';

import Card, { Layout } from '../../../components/foundation/Card';

import styles from './index.module.scss';

const playlists = [
  { name: 'Turnt The Fuck Up', owner: 'Me' },
  { name: '90s Hits', owner: 'Apple Music' },
  { name: 'Getting Started', owner: 'DisCo Music' },
  { name: 'Underground Faves', owner: 'Soundcloud' },
  { name: 'Summer Jams', owner: 'Spotify' },
];

export default function Playlists() {
  return (
    <div>
      {playlists.map(pl => (
        <Card layout={Layout.Horizontal}>
          <div className={styles.PlaylistAlbumArtWrapper}>
            <Icon
              name={IconName.SampleAlbumArt}
              className={styles.PlaylistAlbumArt}
            />
          </div>
          <div className={styles.PlaylistDetails}>
            <Label
              value={pl.name}
              size={LabelSize.Small}
              className={styles.ModeLabel}
            />
            {/* <Label
              value={pl.owner}
              size={LabelSize.Small}
              className={styles.ModeLabel}
            /> */}
          </div>
        </Card>
      ))}
    </div>
  );
}
