import { useEffect } from 'react';
import * as Models from 'disco-music-api';

import { useDevices } from '../../../../context/devices';
import { usePlayer } from '../../../../context/player';
import Button from '../../../foundation/Button';
import Modal from '../../../foundation/Modal';

export interface Props {
  onToggle: () => Promise<void>;
}
export default function ServicesModal({ onToggle }: Props) {
  const [player] = usePlayer();

  // const currentDevice = player?.currentDevice;
  // const otherDevices = player?.otherDevices || [];

  useEffect(() => {
    const loadDevices = async () => {
      player?.refreshDevices();
    };
    loadDevices();
  }, []);

  return (
    <Modal onToggle={onToggle}>
      <>
        <h1>Services Manager</h1>
        <div>
          {player?.isAuthorized(Models.Synthetic.Services.Service.Spotify) && (
            <>
              <span
                style={
                  player?.source === Models.Synthetic.Services.Service.Spotify
                    ? { backgroundColor: 'green' }
                    : {}
                }
                onClick={async () =>
                  await player?.changeSource(
                    Models.Synthetic.Services.Service.Spotify
                  )
                }
              >
                Spotify
              </span>
              <br />
            </>
          )}
          {player?.isAuthorized(
            Models.Synthetic.Services.Service.AppleMusic
          ) && (
            <span
              style={
                player?.source === Models.Synthetic.Services.Service.AppleMusic
                  ? { backgroundColor: 'green' }
                  : {}
              }
              onClick={async () =>
                await player?.changeSource(
                  Models.Synthetic.Services.Service.AppleMusic
                )
              }
            >
              Apple Music
            </span>
          )}
        </div>
      </>
    </Modal>
  );
}
