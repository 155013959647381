import React from 'react';

import styles from './index.module.scss';

export enum IconName {
  SidebarExplore = 'sidebar_explore',
  SidebarGenres = 'sidebar_genres',
  SidebarAlbums = 'sidebar_albums',
  SidebarArtists = 'sidebar_artists',
  SidebarRadio = 'sidebar_radio',
  SidebarParties = 'sidebar_parties',
  SidebarSearch = 'sidebar_search',
  Settings = 'settings',
  CollapsibleOpen = 'collapsible_open',
  CollapsibleClosed = 'collapsible_closed',
  PartyDrink = 'party_drink',
  // TODO remove maybe
  UserPlaceholder = 'user_placeholder',
  SampleAlbumArt = 'sample_album_art',
}

export enum IconSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

interface Props {
  name: IconName;
  url?: string;
  size?: IconSize;
  className?: string;
  handleClick?: (event: React.MouseEvent<HTMLImageElement>) => void;
}

export default function Icon(props: Props) {
  const { name, url, size, className, handleClick } = props;
  const iconURL = url ? url : require(`../../../assets/${name}.png`).iconURL;
  return (
    <img
      src={iconURL}
      alt={name}
      className={`${size ? styles[size] : ''} ${className || ''}`}
      onClick={handleClick}
    />
  );
}
