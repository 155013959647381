import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import ProvideAuth, { useAuth, renderUnauthenticated } from './context/auth';
import ProvidePlayer from './context/player';
import ProvideDevices, { useDevices } from './context/devices';
import ProvidePrimaryNavigationControl, {
  usePrimaryNavigationControl,
} from './context/primaryNavigationControl';
import ProvideToasts from './context/toast';

import { CustomHeaderPageProps } from './pages';
import WaitlistPage from './pages/Waitlist';
import LoginPage from './pages/Login';
import VerifyPage from './pages/Verify';
import RegisterPage from './pages/Register';
import PartiesPage from './pages/Parties';
import LibraryPage from './pages/Library';
import SearchPage from './pages/Search';
import PartyPlayerSettings from './pages/PartyPlayer/Settings';
import SettingsPage from './pages/Settings';
import SpotifyCallbackPage from './pages/Services/SpotifyCallback';
import AlbumPage from './pages/Album';
import ArtistPage from './pages/Artist';

import SideBar from './components/SideBar';
import NavBar from './components/NavBar';
import Player from './components/Player';

import styles from './App.module.scss';
import Modal from './components/foundation/Modal';
import Button from './components/foundation/Button';

// BUGS:
// on signup, initial load of /parties and /player fails due to race condition on setup done by player middleware
// we should wait for the is-authenticated response before issuing subsequent requests on signup/login
function App() {
  return (
    <ProvideToasts>
      <ProvideAuth>
        <Router>
          <Switch>
            <PublicRoute exact path="/">
              <Redirect
                to={{
                  pathname: '/login',
                }}
              />
            </PublicRoute>

            {/* <PublicRoute exact path="/waitlist">
              <WaitlistPage />
            </PublicRoute> */}

            <PublicRoute exact path="/login">
              <LoginPage />
            </PublicRoute>

            <PublicRoute exact path="/verify/:username">
              <VerifyPage />
            </PublicRoute>

            <PublicRoute exact path="/register">
              <RegisterPage />
            </PublicRoute>

            <ProvidePlayer>
              <ProvideDevices>
                <ProvidePrimaryNavigationControl>
                  {/* <ProvideServices> */}
                  <PrivateRoute exact path="/parties">
                    <PageWrapper pageComponent={PartiesPage} />
                  </PrivateRoute>

                  <PrivateRoute exact path="/library">
                    <PageWrapper pageComponent={LibraryPage} />
                  </PrivateRoute>

                  <PrivateRoute exact path="/search">
                    <PageWrapper pageComponent={SearchPage} />
                  </PrivateRoute>

                  <PrivateRoute exact path="/parties/active/settings">
                    <PageWrapper pageComponent={PartyPlayerSettings} />
                  </PrivateRoute>

                  <PrivateRoute exact path="/settings">
                    <PageWrapper pageComponent={SettingsPage} />
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/services/spotify/authorize/callback"
                  >
                    <PageWrapper pageComponent={SpotifyCallbackPage} />
                  </PrivateRoute>

                  <PrivateRoute exact path="/albums/:id">
                    <PageWrapper pageComponent={AlbumPage} />
                  </PrivateRoute>

                  <PrivateRoute exact path="/artists/:id">
                    <PageWrapper pageComponent={ArtistPage} />
                  </PrivateRoute>

                  {/* </ProvideServices> */}
                </ProvidePrimaryNavigationControl>
              </ProvideDevices>
            </ProvidePlayer>
          </Switch>
        </Router>
      </ProvideAuth>
    </ProvideToasts>
  );
}

export default App;

// from: https://reactrouter.com/web/example/auth-workflow
// A wrapper for <Route> that
// - redirects to the login screen if you're not yet authenticated.
interface PrivateRouteProps {
  children: any;
  [key: string]: any;
}
function PrivateRoute({ children, ...rest }: PrivateRouteProps) {
  const auth = useAuth();
  if (auth.refreshing) return null;
  if (!auth.user) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )}
      />
    );
  }
  if (auth.promptUserForDeviceChange) return <SwitchDevicesModal />;
  return <Route {...rest} render={() => children} />;
}

interface PublicRouteProps {
  children: any;
  [key: string]: any;
}
function PublicRoute({ children, ...rest }: PrivateRouteProps) {
  const auth = useAuth();
  if (auth.refreshing) return null;
  if (auth.user) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/parties',
              state: { from: location },
            }}
          />
        )}
      />
    );
  }
  return <Route {...rest} render={() => children} />;
}

// TODO: move these helper components into a root utils.tsx or similar, then impoprt Menu enum from child components
// Wraps unauthenticated/landing-type pages
// - redirects to a dismissable welcome page/alert that pushes users to login to supported services
// (the app is kinda useless otherwise)
interface PageWrapperProps {
  pageComponent: React.FC<CustomHeaderPageProps>;
}

function PageWrapper(props: PageWrapperProps) {
  let auth = useAuth();

  // this is applicable to mobile devices only, as the sidebar will
  const { toggleSideBar } = usePrimaryNavigationControl();
  const { pageComponent } = props;

  return (
    <div className={`${styles.AppWrapper}`}>
      <SideBar />
      <NavBar onToggleSideBar={toggleSideBar}>
        {auth.user ? pageComponent : renderUnauthenticated}
      </NavBar>
      <Player />
    </div>
  );
}

function SwitchDevicesModal() {
  const auth = useAuth();
  const { activeDevice, otherDevices, requestingDeviceDetails } = useDevices();
  return (
    <Modal
      onToggle={async () =>
        console.log('SwitchDevicesModal dismiss not setup yet')
      }
    >
      <>
        <h1>Looks like you’re listening on another device.</h1>
        <h6>To resume listening here, just press "Switch Devices".</h6>
        <div style={{ padding: '1rem' }} />
        <div style={{ display: 'flex' }}>
          <div style={{ flexDirection: 'column', marginRight: '6rem' }}>
            <h5 style={{ margin: 0 }}>Current Listening Device</h5>
            <span>
              {'- ' +
                (activeDevice
                  ? `${activeDevice.clientName || ''} on ${
                      activeDevice.osName || ''
                    } ${activeDevice.deviceModel || ''}`.trim()
                  : 'None')}
            </span>
          </div>
          <div style={{ flexDirection: 'column' }}>
            <h5 style={{ margin: 0 }}>Your Device</h5>
            <span>
              {'- ' +
                `${requestingDeviceDetails.clientName || ''} on ${
                  requestingDeviceDetails.osName || ''
                } ${requestingDeviceDetails.deviceModel || ''}`.trim()}
            </span>
          </div>
        </div>
        <div style={{ padding: '1rem' }} />
        <Button
          name={'Switch Devices'}
          handleClick={async event => {
            event.preventDefault();
            await auth.refresh();
          }}
        />
      </>
    </Modal>
  );
}
