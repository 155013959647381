// HEAVILY adapted from: https://usehooks.com/useAuth/
import { useState, useEffect, useContext, createContext } from 'react';
// import Cookies from 'js-cookie';
import DiscoMusicAPI from 'disco-music-api';
import * as Models from 'disco-music-api';
import usePolling from '../effects/polling';

export interface AuthContext {
  refreshing: boolean;
  user: Models.User.User | null;
  promptUserForDeviceChange: boolean;
  login: (username: string, twoFactorCode: string) => Promise<Models.User.User>;
  logout: () => Promise<void>;
  register: (
    username: string,
    phoneNumber: string,
    inviteCode: string
  ) => Promise<void>;
  requestVerification: (
    username: string,
    method: Models.Synthetic.Auth.VerificationMethod
  ) => Promise<void>;
  verify: (username: string, code: string) => Promise<void>;
  refresh: () => void;
}

const INITIAL_AUTH_CONTEXT_VALUE: AuthContext = {
  refreshing: true,
  user: null,
  promptUserForDeviceChange: false,
  login: _login,
  logout: _logout,
  register: _register,
  requestVerification: _requestVerification,
  verify: _verify,
  refresh: () => void 0,
};
const authContext = createContext<AuthContext>(INITIAL_AUTH_CONTEXT_VALUE);

export interface ProvideAuthProps {
  children: any;
}
// Provider component that wraps your app and makes auth object available
// to any child component that calls useAuth()
export default function ProvideAuth({ children }: ProvideAuthProps) {
  const auth = useProvideAuth(); // init and ref auth state
  return <authContext.Provider value={auth}>{children}</authContext.Provider>; // provide context for `auth` defined above to child components
}

// Hook for child components to get the auth object and re-render when
// it changes
export const useAuth = (): AuthContext => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth(): AuthContext {
  const [refreshing, setRefreshing] = useState<boolean>(true);
  // const [refresh, setRefresh] = useState<boolean>(false);
  const [user, setUser] = useState<Models.User.User | null>(null);
  const [
    promptUserForDeviceChange,
    setPromptUserForDeviceChange,
  ] = useState<boolean>(false);

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  const fetchAuthState = async (changeActiveDevice: boolean = false) => {
    // if (refreshing) return;

    // setRefreshing(true);
    try {
      const response = await DiscoMusicAPI.Auth.isAuthenticated(
        changeActiveDevice
      );
      if (response.user) setUser(response.user);
      setPromptUserForDeviceChange(!!response.promptDeviceChange);
    } catch (e) {
      console.error(e);
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchAuthState();
    return () => {
      // setUser(null);
      // setPromptUserForDeviceChange(false);
      // setRefreshing(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePolling(fetchAuthState, 5000);

  return {
    refreshing,
    user,
    promptUserForDeviceChange,
    login: async (
      username: string,
      twoFactorCode: string
    ): Promise<Models.User.User> => {
      const user = await _login(username, twoFactorCode);
      setUser(user);
      // setRefresh(!refresh)
      return user;
    },
    logout: async (): Promise<void> => {
      await _logout();
      setUser(null);
      // removeCookie('connect.sid');
    },
    register: async (
      phoneNumber: string,
      password: string,
      inviteCode: string
    ): Promise<void> => {
      await _register(phoneNumber, password, inviteCode);
    },
    requestVerification: async (
      username: string,
      method: Models.Synthetic.Auth.VerificationMethod
    ): Promise<void> => {
      await _requestVerification(username, method);
    },
    verify: async (username: string, code: string): Promise<void> => {
      await _verify(username, code);
    },
    refresh: () => {
      // setRefresh(true);
      setRefreshing(true);
      fetchAuthState(true);
    },
  };
}

// TODO port to std lib
// Auth methods
async function _register(
  username: string,
  phoneNumber: string,
  inviteCode: string
): Promise<void> {
  try {
    const { success, message } = await DiscoMusicAPI.Auth.register({
      username,
      phoneNumber,
      inviteCode,
    });
    if (!success) {
      throw new Error(
        `Registration failed, ${message || 'no reason was provided'}`
      );
    }
  } catch (error) {
    throw new Error(`Registration failed: ${error.message}`);
  }
}

async function _requestVerification(
  username: string,
  method: Models.Synthetic.Auth.VerificationMethod
): Promise<void> {
  try {
    const { success, message } = await DiscoMusicAPI.Auth.requestVerification({
      username,
      verificationMethod: method,
    });
    if (!success) {
      throw new Error(
        `Verification request failed, ${message || 'no reason was provided'}`
      );
    }
  } catch (error) {
    throw new Error(`Verification request failed: ${error.message}`);
  }
}

async function _verify(username: string, code: string): Promise<void> {
  try {
    const { success, message } = await DiscoMusicAPI.Auth.verify({
      username,
      code,
    });
    if (!success) {
      throw new Error(
        `Verification failed, ${message || 'no reason was provided'}`
      );
    }
  } catch (error) {
    throw new Error(`Verification failed: ${error.message}`);
  }
}

async function _login(
  username: string,
  twoFactorCode: string
): Promise<Models.User.User> {
  try {
    const { success, message } = await DiscoMusicAPI.Auth.login({
      username,
      twoFactorCode,
    });
    if (!success) {
      throw new Error(`Login failed, ${message || 'no reason was provided'}`);
    }
    const { user } = await DiscoMusicAPI.Auth.isAuthenticated();
    if (!user) {
      throw new Error('Login failed, please try again.');
    }
    return user;
  } catch (error) {
    throw new Error(`Invalid credentials: ${error.message}`);
  }
}

async function _logout(): Promise<void> {
  const { success } = await DiscoMusicAPI.Auth.logout();
  if (!success) {
    throw new Error(`Logout failed, please try again.`);
  }
}

export function renderUnauthenticated() {
  return (
    <div>
      <h3>You Are Not Authenticated</h3>
    </div>
  );
}
