import React from 'react';

import styles from './index.module.scss';

interface Props {
  children: JSX.Element;
  icon?: JSX.Element;
  name?: string;
  className?: string;
  handleClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export default function Alert(props: Props) {
  const { icon, name, children, className, handleClick } = props;
  return (
    <div className={`${styles.Alert} ${className || ''}`} onClick={handleClick}>
      {icon || name ? (
        <div className={styles.AlertHeader}>
          {/* icon */}
          {/* name */}
        </div>
      ) : null}
      <div className={styles.AlertContent}>{children}</div>
    </div>
  );
}
