import { useEffect } from 'react';

// hook that manages a Spotify Connect SDK client given an access token
// NOTES:
// - PLEASE memoize `memo()` your pollFn before passing it in!!!!
export default function usePolling(pollFn: any, intervalMs: number): void {
  useEffect(() => {
    const timer = setInterval(pollFn, intervalMs);
    return () => clearInterval(timer);
  }, [pollFn, intervalMs]);
}
