import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from 'react';
import DiscoMusicAPI from 'disco-music-api';
import * as Models from 'disco-music-api';

import useSpotifyClient from '../effects/spotifyClient';
import useAppleMusicClient from '../effects/appleMusicClient';
import ServicesModal, {
  ModalAction,
  ServicesModalPayload,
  AuthorizedServices,
} from '../components/ServicesModal';
import useLocalStorageJSON from '../effects/localStorage';
import { useToast } from './toast';

enum WebSocketEvent {
  PlayerStateSync = 'PLAYER_STATE_SYNC',
  Heartbeat = 'HEARTBEAT',
}

enum WebSocketServerEvent {
  PlayerSync = 'PLAYER_SYNC',
  PartyMemberJoined = 'PARTY_MEMBER_JOINED',
  PartyMemberLeft = 'PARTY_MEMBER_LEFT',
  PartyQueueUpdated = 'PARTY_QUEUE_UPDATED',
  PartyEnded = 'PARTY_ENDED',
}

export interface TrackQueue {
  source: string | null;
  tracks: Models.Track.Track[];
}

export interface MusicPlayer {
  // Manage DisCo player (wraps around player instances from supported services)
  isPaused: boolean;
  currentTrack: Models.NowPlayingTrack.NowPlayingTrackWithTrackInfo | null;
  queues: {
    // next: TrackQueue;
    [Models.Queue.Type.Main]: {
      queue: Models.Queue.Queue | null;
      tracks: Models.QueuedTrack.QueuedTrackWithTrackInfo[];
    };
  };
  history: Models.QueuedTrackHistoryItems.QueuedTrackHistoryItemWithTrackInfo[];
  enqueue: (trackId: string, track: Models.Track.Track) => Promise<void>;
  progressMs: number;
  setProgressMs: (progressMs: number) => void;
  scrubProgressMs: (progressMs: number) => Promise<void>;
  togglePlay: () => Promise<void>;
  previousTrack: () => void;
  nextTrack: () => void;
  setActiveDevice: (deviceId: string) => void;
  refreshDevices: () => Promise<void>;

  // Access track catalogues of supported services
  search: (
    query: string
  ) => Promise<{
    tracks: Models.Track.Track[];
    albums: Models.Album.Album[];
    artists: Models.Artist.Artist[];
  }>;

  // Manage player source state
  source: Models.Synthetic.Services.Service | null;
  changeSource: (
    service: Models.Synthetic.Services.Service | null
  ) => Promise<void>;
  sourceChangeModal: ServicesModalPayload | null;
  setSourceChangeModal: (payload: ServicesModalPayload | null) => void;

  // Manage authorization for supported services
  authorizedServices: AuthorizedServices;
  isAuthorized: (service: Models.Synthetic.Services.Service) => boolean;
  authorize: (
    service: Models.Synthetic.Services.Service
  ) => Promise<string | null>;
  authorizeCallback: (
    service: Models.Synthetic.Services.Service,
    code: string,
    state: string
  ) => Promise<void>;
  unauthorize: (service: Models.Synthetic.Services.Service) => Promise<void>;

  // Party settings
  toggleParty: () => Promise<void>;
  joinParty: (partyId: string) => Promise<void>;
  leaveParty: () => Promise<void>;
  partyId?: string;
  partySource: Models.Synthetic.Services.Service | null;
  isPartyHost?: boolean;
  currentParty?: Models.Party.PartyState | null;
  parties: Models.Player.PartyPlayer[];
  partyMembers: Models.User.User[];
  refreshParties: () => void;

  // Other..
  currentDevice: any;
  otherDevices: any[];
  // isDeviceActive: boolean
}
export interface ServiceStatuses {
  [Models.Synthetic.Services.Service
    .Spotify]: Models.Synthetic.Services.ServiceStatus;
  [Models.Synthetic.Services.Service
    .AppleMusic]: Models.Synthetic.Services.ServiceStatus;
}
export type PlayerContext = [MusicPlayer | null, ServiceStatuses];

const INITIAL_AUTH_CONTEXT_VALUE: PlayerContext = [
  null,
  {
    [Models.Synthetic.Services.Service.Spotify]:
      Models.Synthetic.Services.ServiceStatus.Inactive,
    [Models.Synthetic.Services.Service.AppleMusic]:
      Models.Synthetic.Services.ServiceStatus.Inactive,
  },
];
const musicPlayerContext = createContext<PlayerContext>(
  INITIAL_AUTH_CONTEXT_VALUE
);

export interface ProvideMusicPlayerProps {
  children: any;
}
// Provider component that wraps your app and makes auth object available
// to any child component that calls useAuth()
export default function ProvideMusicPlayer({
  children,
}: ProvideMusicPlayerProps) {
  const musicPlayer = useProvideMusicPlayer(); // init and ref music player state

  const player = musicPlayer[0];

  return (
    <musicPlayerContext.Provider value={musicPlayer}>
      {player?.sourceChangeModal && (
        <ServicesModal
          action={player.sourceChangeModal.action}
          subject={player.sourceChangeModal.subject}
          onDismiss={async (
            source: Models.Synthetic.Services.Service | null | undefined
          ) => {
            if (source !== undefined) {
              await player?.changeSource(source);
            }
            player?.setSourceChangeModal(null);
          }}
          authorizedServices={player.authorizedServices}
        />
      )}
      {children}
    </musicPlayerContext.Provider>
  );
}

// Hook for child components to get the auth object and re-render when
// it changes
export const usePlayer = (): PlayerContext => {
  return useContext(musicPlayerContext);
};

// Provider hook that creates auth object and handles state
function useProvideMusicPlayer(): PlayerContext {
  const [refreshing, setRefreshing] = useState<boolean>(true);
  const [refreshAuthState, setRefreshAuthState] = useState<boolean>(false);
  const [refreshParties, setRefreshParties] = useState<boolean>(false);
  const [isSynced, setIsSynced] = useState<boolean>(false);

  const { notify } = useToast();

  // player source state
  const [
    source,
    setSource,
  ] = useState<Models.Synthetic.Services.Service | null>(null);
  const [
    sourceChangeModal,
    setSourceChangeModal,
  ] = useLocalStorageJSON<ServicesModalPayload | null>(
    'state.player.source.modal',
    null
  );

  const [isPartyHost, setIsPartyHost] = useState(false);

  // streaming services state
  const [spotifyToken, setSpotifyToken] = useState<string | null>(null);
  const [appleMusicToken, setAppleMusicToken] = useState<string | null>(null);
  const [
    appleMusicStatus,
    setAppleMusicStatus,
  ] = useState<Models.Synthetic.Services.ServiceStatus>(
    Models.Synthetic.Services.ServiceStatus.Inactive
  );
  const [
    spotifyStatus,
    setSpotifyStatus,
  ] = useState<Models.Synthetic.Services.ServiceStatus>(
    Models.Synthetic.Services.ServiceStatus.Inactive
  );
  const [
    authorizedServices,
    setAuthorizedServices,
  ] = useState<AuthorizedServices>({
    [Models.Synthetic.Services.Service.Spotify]: false,
    [Models.Synthetic.Services.Service.AppleMusic]: false,
  });

  // player state to wrap around streaming service SDKs and their player state
  const [
    personalPlayer,
    setPersonalPlayer,
  ] = useState<Models.Player.Player | null>(null);
  const [player, setPlayer] = useState<Models.Player.Player | null>(null);
  const [party, setParty] = useState<Models.Party.PartyState | null>(null);
  const [parties, setParties] = useState<Models.Player.PartyPlayer[]>([]);
  const [partyMembers, setPartyMembers] = useState<Models.User.User[]>([]);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [progressMs, setProgressMs] = useState(0);
  const [
    nowPlayingTrack,
    setNowPlayingTrack,
  ] = useState<Models.NowPlayingTrack.NowPlayingTrackWithTrackInfo | null>(
    null
  );
  const progressMsIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [queue, setQueue] = useState<Models.Queue.Queue | null>(null);
  const [queuedTracks, setQueuedTracks] = useState<
    Models.QueuedTrack.QueuedTrackWithTrackInfo[]
  >([]);
  const [history, setHistory] = useState<
    Models.QueuedTrackHistoryItems.QueuedTrackHistoryItemWithTrackInfo[]
  >([]);

  // streaming service SDKs player state
  const spotifyPlayer = useSpotifyClient(spotifyToken);
  const appleMusicPlayer = useAppleMusicClient(appleMusicToken);

  useEffect(() => {
    const task = async () => {
      const response1 = await DiscoMusicAPI.Player.getParties();
      setParties(response1.parties);
      const response2 = await DiscoMusicAPI.Player.getParty();
      setParty(response2.party);
      setPartyMembers(response2.members);
    };
    task();
  }, [refreshParties]);

  const [refreshServices, setRefreshServices] = useState(true);
  useEffect(() => {
    const work = async () => {
      if (!refreshServices) return;
      const response = await DiscoMusicAPI.Services.findAll();
      setSpotifyToken(response.SPOTIFY.token);
      setSpotifyStatus(response.SPOTIFY.status);
      setAppleMusicToken(response.appleMusicDeveloperToken);
      setAppleMusicStatus(response.APPLE_MUSIC.status);
    };

    try {
      work();
    } catch (e) {
      console.error(e);
    } finally {
      setRefreshServices(false);
    }
    return () => {
      setSpotifyToken(null);
      setAppleMusicToken(null);
      setSpotifyStatus(Models.Synthetic.Services.ServiceStatus.Inactive);
      setAppleMusicStatus(Models.Synthetic.Services.ServiceStatus.Inactive);
    };
  }, [refreshServices]);

  // run streaming service discovery and fetch latest player state
  useEffect(() => {
    const fetchAuthState = async () => {
      // setRefreshing(true);
      try {
        const response = await DiscoMusicAPI.Player.get();
        setProgressMs(response.nowPlayingTrack?.playbackProgressMs || 0);

        setPersonalPlayer(response.personalPlayer);
        setPlayer(response.player);
        setSource(response.player.source);
        setNowPlayingTrack(response.nowPlayingTrack);
        setQueue(response.queue);
        setQueuedTracks(response.queuedTracks || []);
        setHistory(response.queuedTrackHistoryItems);
        setParty(response.party);
        setIsPartyHost(
          response.party?.hostUserId === response.personalPlayer.userId
        );
      } catch (e) {
        console.error(e);
      } finally {
        setRefreshing(false);
      }
    };

    fetchAuthState();
    return () => {
      clearInterval(progressMsIntervalRef.current as any);
      setSpotifyToken(null);
      setAppleMusicToken(null);
      setSpotifyStatus(Models.Synthetic.Services.ServiceStatus.Inactive);
      setAppleMusicStatus(Models.Synthetic.Services.ServiceStatus.Inactive);
    };
  }, [refreshAuthState]);

  // const [playNext, setPlayNext] = useState(0)
  // useEffect(() => {
  //   if (playNext === 0) return

  // }, [playNext])

  // set streaming service authorization statuses
  const isSpotifyAvailable = !!spotifyPlayer;
  const isAppleMusicAvailable =
    !!appleMusicPlayer && appleMusicPlayer[0].isAuthorized;
  useEffect(() => {
    setAuthorizedServices({
      [Models.Synthetic.Services.Service.Spotify]: isSpotifyAvailable,
      [Models.Synthetic.Services.Service.AppleMusic]: isAppleMusicAvailable,
    });

    // TODO what happens if on load, a 1 or more service sessions have expired
  }, [isSpotifyAvailable, isAppleMusicAvailable]);

  // open web socket connection to sync player state to server. using web sockets
  // for updates to data such as NowPlayingTrack.playbackProgressMs (which updates every second)
  const [ws, setWs] = useState<WebSocket | null>(null);
  const wsKeepAliveIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [wsReady, setWsReady] = useState(false);
  const [, setWsError] = useState(false);
  useEffect(() => {
    const playerId = personalPlayer?.id;
    if (!playerId) return;

    // `ws://${
    //   DiscoMusicAPI.Common.servers.development.split('//')[1]
    // }/web-sockets/player/${playerId}/sync`
    // `ws://60a15b957079.ngrok.io/web-sockets/player/${playerId}/sync`
    // const WS_URL = `wss://ddd2c2342d7e.ngrok.io/web-sockets/player/${playerId}/sync`;
    const WS_URL = `${DiscoMusicAPI.Common.WebSocketServer}/web-sockets/player/${playerId}/sync`;

    const initWs = () => {
      const socket = new WebSocket(WS_URL);
      socket.addEventListener('open', event => {
        console.log('event.open:', event);

        setWs(socket);
        setWsReady(true);
        const wsKeepAlive = function () {
          const heartbeat = new Date();
          socket.send(
            JSON.stringify({
              event: WebSocketEvent.Heartbeat,
              data: { heartbeat },
            })
          );
        };
        wsKeepAliveIntervalRef.current = setInterval(wsKeepAlive, 20000);
      });
      socket.addEventListener('error', event => {
        console.log('event.error:', event);
        setWsError(true);
      });

      socket.addEventListener('close', event => {
        console.log('event.close:', event);
        console.log('socket.onclose:', socket.onclose);
        clearInterval(wsKeepAliveIntervalRef.current as any);
        setWsReady(false);
        setWs(null);
        wsKeepAliveIntervalRef.current = null;

        // attempt to re-open closed WebSocket connections resulting from low-level error
        // we seem to hit these scenarios on heroku when we background the tab and return to it, nondeterministically
        setWsError(previousValue => {
          if (!previousValue) return false;
          initWs();
          return true;
        });
      });
      socket.addEventListener('message', async e => {
        const { event, data } = JSON.parse(e.data);
        console.log('received event.message:', event, data);
        switch (event) {
          case WebSocketServerEvent.PlayerSync:
            setProgressMs(data.nowPlayingTrack?.playbackProgressMs || 0);
            setPlayer(data.player);
            setSource(data.player.source);
            setNowPlayingTrack(data.nowPlayingTrack);
            setQueue(data.queue);
            setQueuedTracks(data.queuedTracks || []);
            setHistory(data.queuedTrackHistoryItems);
            setIsPlaying(data.player.isPlaying);

            await syncLocalPlayer(
              data.player.source,
              data.nowPlayingTrack,
              data.player.isPlaying,
              spotifyPlayer?.[0],
              appleMusicPlayer?.[0]
            );
            // if we make distinct events for prev/next, we don't need to check for an interval
            if (!progressMsIntervalRef.current && data.player.isPlaying) {
              setProgressInterval(
                progressMsIntervalRef,
                setProgressMs,
                data.nowPlayingTrack?.durationMs || 0,
                true
              );
            }
            break;
          case WebSocketServerEvent.PartyQueueUpdated:
            notify({
              source: 'Party',
              title: 'Queue Updated',
              body: data.message,
            });
            setNowPlayingTrack(data.nowPlayingTrack);
            setQueue(data.queue);
            setQueuedTracks(data.queuedTracks || []);
            break;

          case WebSocketServerEvent.PartyMemberJoined:
            notify({
              source: 'Party',
              title: 'New Guest',
              body: data.message,
            });
            break;
          case WebSocketServerEvent.PartyEnded:
            notify({
              source: 'Party',
              title: 'Party Ended',
              body: data.message,
            });
            setRefreshAuthState(prev => !prev);
            break;
          default:
            console.log(e.data);
            break;
        }
      });
    };

    initWs();

    const handleAppBackgroundedWrapper = () => {
      const closeWs = () => {
        setWs(socket => {
          clearInterval(wsKeepAliveIntervalRef.current as any);
          setWsReady(false);
          wsKeepAliveIntervalRef.current = null;
          if (!socket) {
            console.log(
              'Attempted to close a WebSocket connection that has already been closed.'
            );
            return null;
          }
          socket.close();
          return null;
        });
      };
      handleAppBackgrounded(initWs, closeWs, !party || isPartyHost);
    };

    document.addEventListener('visibilitychange', handleAppBackgroundedWrapper);

    return () => {
      document.removeEventListener(
        'visibilitychange',
        handleAppBackgroundedWrapper
      );
    };
  }, [player?.id]);

  // TODO sync spotify to current player if spotify is active
  // const isSpotifyPaused = !!spotifyPlayer?.[1].isPaused;
  // useEffect(() => {
  //   if (isPlaying === !isSpotifyPaused) return;
  //   setIsPlaying(!isSpotifyPaused);
  // }, [isSpotifyPaused]);

  // sync streaming service player with server provided player (NowPlayingTrack) state
  useEffect(() => {
    if (!isSynced && (spotifyPlayer || appleMusicPlayer) && nowPlayingTrack) {
      console.log('syncing');
      syncLocalPlayer(
        source,
        nowPlayingTrack,
        false,
        spotifyPlayer?.[0],
        appleMusicPlayer?.[0]
      );
      if (!isSynced) setIsSynced(true);
    }
  }, [nowPlayingTrack, spotifyPlayer, appleMusicPlayer, source]);

  useEffect(() => {
    const work = async () => {
      if (refreshing) return;

      const previousIsPlaying = !isPlaying;
      if (previousIsPlaying === isPlaying) return;

      if (!party || isPartyHost) {
        const spotifyAction = async (sdk: Spotify.Player) => {
          if (previousIsPlaying) {
            await sdk.pause();
          } else {
            if (!nowPlayingTrack?.trackId) {
              console.log('no track to play:', nowPlayingTrack);
            } else {
              await DiscoMusicAPI.Player.play(nowPlayingTrack.trackId);
              await sdk.seek(nowPlayingTrack.playbackProgressMs);

              await sdk.resume();
            }
          }
        };
        const appleMusicAction = async (sdk: MusicKit.MusicKitInstance) => {
          if (previousIsPlaying) {
            sdk.pause();
          } else {
            await sdk.play();
            await sdk.seekToTime(Math.floor(progressMs / 1000));
          }
        };

        await dispatchPlayerSDKAction(
          source,
          spotifyPlayer?.[0] || null,
          spotifyAction,
          appleMusicPlayer?.[0] || null,
          appleMusicAction
        );
      }

      if (isPlaying) {
        setProgressInterval(
          progressMsIntervalRef,
          setProgressMs,
          nowPlayingTrack?.durationMs || 0,
          isPlaying
        );
      } else {
        clearInterval(progressMsIntervalRef.current as any);
      }
    };
    work();
  }, [isPlaying, refreshing]);

  // ONLY runs for non-parties and party hosts
  const canSyncProgressMs = wsReady && (!party || isPartyHost);
  useEffect(() => {
    if (!canSyncProgressMs) return;
    if (!ws) {
      console.error(
        'attempted to setProgressMs without an active websocket connection!'
      );
      return;
    }
    ws.send(
      JSON.stringify({
        event: WebSocketEvent.PlayerStateSync,
        data: { progressMs },
      })
    );
  }, [canSyncProgressMs, progressMs]);

  // ONLY runs when the track has ended
  const durationMs = nowPlayingTrack?.playbackDurationMs || 0;
  useEffect(() => {
    console.log(`progressMs: ${progressMs} | durationMs: ${durationMs}`);
    if (progressMs === 0 || durationMs === 0 || progressMs < durationMs) return;

    const nextTrack = async () => {
      try {
        await DiscoMusicAPI.Player.next();
        console.log(
          'current track ended, skipped to next track automatically!'
        );
      } catch (err) {
        console.error(err);
      }
    };
    clearInterval(progressMsIntervalRef.current as any);
    progressMsIntervalRef.current = null;
    nextTrack();
  }, [progressMs, durationMs]);

  useEffect(() => {
    if (!wsReady) return;
    if (party && !isPartyHost) return;

    if (!ws) {
      console.error(
        'attempted to setProgressMs without an active websocket connection!'
      );
      return;
    }
    ws.send(
      JSON.stringify({
        event: WebSocketEvent.PlayerStateSync,
        data: { progressMs },
      })
    );
  }, [wsReady, progressMs]);

  // TODO move this into an effect
  let currentDevice = null;
  let otherDevices: any[] = [];
  if (spotifyPlayer) {
    currentDevice = spotifyPlayer[1].currentDevice;
    otherDevices = spotifyPlayer[1].otherDevices;
  }

  return [
    player || spotifyPlayer || appleMusicPlayer
      ? {
          // Manage DisCo player (wraps around player instances from supported services)
          isPaused: !isPlaying,
          currentTrack: nowPlayingTrack,
          queues: {
            [Models.Queue.Type.Main]: { queue, tracks: queuedTracks },
          },
          history,
          enqueue: async (trackId: string, track: Models.Track.Track) => {
            // TODO revisit to manage action better (play later, play next, play now)
            // currently just appends to queue if nowPlaying is already filled
            const { body } = await DiscoMusicAPI.Player.enqueue(trackId);
            setPlayer(body.player);
            // setSource(body.player.source); TODO need to handle this special to alert user and toggle between players
            setNowPlayingTrack(body.nowPlayingTrack);
            setQueue(body.queue);
            setQueuedTracks(body.queuedTracks || []);

            // TODO what if a track is missing the needed service ID??
            if (!party || isPartyHost) {
              const spotifyAction = (_sdk: Spotify.Player) => void 0;
              const appleMusicAction = async (
                sdk: MusicKit.MusicKitInstance
              ) => {
                const enqueuePayload = {
                  items: [],
                  song: track.appleMusicId,
                } as any;

                if ((body.queuedTracks || []).length === 0) {
                  await sdk.setQueue(enqueuePayload);
                  setIsPlaying(!isPlaying);
                }
              };

              await dispatchPlayerSDKAction(
                source,
                spotifyPlayer?.[0] || null,
                spotifyAction,
                appleMusicPlayer?.[0] || null,
                appleMusicAction
              );
            }
          },
          progressMs,
          setProgressMs,
          scrubProgressMs: async (value: number) => {
            // TODO doesnt support dragging of scrubber due to MusicKit JS error from `PlayActivity.prototype.scrub`
            clearInterval(progressMsIntervalRef.current as any);
            setProgressMs(value);

            if (isPlaying) {
              if (!party || isPartyHost) {
                const spotifyAction = (sdk: Spotify.Player) => {
                  sdk.seek(value);
                };
                const appleMusicAction = async (
                  sdk: MusicKit.MusicKitInstance
                ) => {
                  await sdk.seekToTime(Math.floor(value / 1000));
                };
                await dispatchPlayerSDKAction(
                  source,
                  spotifyPlayer?.[0] || null,
                  spotifyAction,
                  appleMusicPlayer?.[0] || null,
                  appleMusicAction
                );
              }

              setProgressInterval(
                progressMsIntervalRef,
                setProgressMs,
                nowPlayingTrack?.durationMs || 0,
                isPlaying
              );
            }
          },
          togglePlay: async () => {
            setIsPlaying(previousValue => {
              const newValue = !previousValue;
              if (!ws) {
                console.error(
                  'attempted to sync player state without an active websocket connection!'
                );
                return newValue;
              }

              ws.send(
                JSON.stringify({
                  event: WebSocketEvent.PlayerStateSync,
                  data: { isPlaying: newValue },
                })
              );
              return newValue;
            });
          },
          previousTrack: async () => {
            clearInterval(progressMsIntervalRef.current as any);
            progressMsIntervalRef.current = null;
            await DiscoMusicAPI.Player.previous();
            console.log('playing previous track fom histoy!');
          },
          nextTrack: async () => {
            await DiscoMusicAPI.Player.next();
          },
          setActiveDevice: () => {
            alert('unimplemented');
          },
          refreshDevices: async () => {
            if (spotifyPlayer) await spotifyPlayer[1].refreshDevices();
          },

          // Access track catalogues of supported services
          search: async query => {
            const results = await DiscoMusicAPI.Services.search(query, 0);
            return results;
          },

          // Access track catalogues of supported services
          source,
          changeSource: async (
            service: Models.Synthetic.Services.Service | null
          ) => {
            if (
              source === Models.Synthetic.Services.Service.Spotify &&
              spotifyPlayer
            ) {
              // console.log('spotifyPlayer:', spotifyPlayer[0]);
              await spotifyPlayer[0].pause();
            } else if (
              source === Models.Synthetic.Services.Service.AppleMusic &&
              appleMusicPlayer
            ) {
              // console.log('appleMusicPlayer:', appleMusicPlayer[0]);
              appleMusicPlayer[0].pause();
            }
            await DiscoMusicAPI.Player.setSource(service);
            if (isPlaying) setIsPlaying(false);
            setSource(service);

            syncLocalPlayer(
              service,
              nowPlayingTrack,
              false,
              spotifyPlayer?.[0],
              appleMusicPlayer?.[0]
            );
          },
          sourceChangeModal,
          setSourceChangeModal,

          // Manage authorization for supported services
          authorizedServices,
          isAuthorized: (service: Models.Synthetic.Services.Service) => {
            if (service === Models.Synthetic.Services.Service.Spotify) {
              return !!spotifyPlayer;
            }

            if (
              service === Models.Synthetic.Services.Service.AppleMusic &&
              appleMusicPlayer
            ) {
              const playerSDK = appleMusicPlayer[0];
              return playerSDK.isAuthorized;
            }
            return false;
          },
          authorize: async (service: Models.Synthetic.Services.Service) => {
            let redirectURI: string | null = null;
            if (service === Models.Synthetic.Services.Service.Spotify) {
              const res = await DiscoMusicAPI.Services.Spotify.authorize(DiscoMusicAPI.Services.Spotify.RedirectTarget.Web);
              redirectURI = res.body.redirectURI;
            } else if (
              service === Models.Synthetic.Services.Service.AppleMusic &&
              appleMusicPlayer
            ) {
              const playerSDK = appleMusicPlayer[0];
              const token = await playerSDK.authorize();
              await DiscoMusicAPI.Services.AppleMusic.authorize(token);

              setAuthorizedServices(previousValue => ({
                ...previousValue,
                [Models.Synthetic.Services.Service.AppleMusic]: true,
              }));

              setSourceChangeModal({
                action: ModalAction.Authenticated,
                subject: Models.Synthetic.Services.Service.AppleMusic,
              });
            }

            setRefreshAuthState(!refreshAuthState);
            setRefreshServices(true);

            return redirectURI;
          },
          authorizeCallback: async (service, code, state) => {
            if (service === Models.Synthetic.Services.Service.Spotify) {
              await DiscoMusicAPI.Services.Spotify.authorizeCallback(
                code,
                state,
                DiscoMusicAPI.Services.Spotify.RedirectTarget.Web
              );
              setAuthorizedServices(previousValue => ({
                ...previousValue,
                [Models.Synthetic.Services.Service.Spotify]: true,
              }));

              setSourceChangeModal({
                action: ModalAction.Authenticated,
                subject: Models.Synthetic.Services.Service.Spotify,
              });
            }

            setRefreshAuthState(!refreshAuthState);
            setRefreshServices(true);
          },
          unauthorize: async (service: Models.Synthetic.Services.Service) => {
            if (
              service === Models.Synthetic.Services.Service.Spotify &&
              spotifyPlayer
            ) {
              await DiscoMusicAPI.Services.Spotify.unauthorize();
              setAuthorizedServices(previousValue => ({
                ...previousValue,
                [Models.Synthetic.Services.Service.Spotify]: false,
              }));
              setSourceChangeModal({
                action: ModalAction.Unauthenticated,
                subject: Models.Synthetic.Services.Service.Spotify,
              });
            } else if (
              service === Models.Synthetic.Services.Service.AppleMusic &&
              appleMusicPlayer
            ) {
              const playerSDK = appleMusicPlayer[0];
              await DiscoMusicAPI.Services.AppleMusic.unauthorize();
              await playerSDK.unauthorize();
              setAuthorizedServices(previousValue => ({
                ...previousValue,
                [Models.Synthetic.Services.Service.AppleMusic]: false,
              }));
              setSourceChangeModal({
                action: ModalAction.Unauthenticated,
                subject: Models.Synthetic.Services.Service.AppleMusic,
              });
            }

            setRefreshAuthState(!refreshAuthState);
            setRefreshServices(true);
          },

          // Party settings
          toggleParty: async () => {
            if (party && isPlaying) setIsPlaying(false);
            await DiscoMusicAPI.Player.toggleParty();
            setRefreshAuthState(!refreshAuthState);
            setRefreshParties(!refreshParties);
          },
          joinParty: async (partyId: string) => {
            await DiscoMusicAPI.Player.joinParty(partyId);
            setRefreshAuthState(!refreshAuthState);
            setRefreshParties(!refreshParties);
          },
          leaveParty: async () => {
            await DiscoMusicAPI.Player.leaveParty();
            setRefreshAuthState(!refreshAuthState);
            setRefreshParties(!refreshParties);
          },
          partyId: player?.partyId,
          partySource: player?.source || null,
          isPartyHost,
          currentParty: party,
          parties,
          partyMembers,
          refreshParties: () => setRefreshParties(!refreshParties),

          // Other...
          currentDevice,
          otherDevices,
        }
      : null,
    {
      [Models.Synthetic.Services.Service.Spotify]: spotifyStatus,
      [Models.Synthetic.Services.Service.AppleMusic]: appleMusicStatus,
    },
  ];
}

async function setProgressInterval(
  progressMsIntervalRef: React.MutableRefObject<NodeJS.Timeout | null>,
  setProgressMs: React.Dispatch<React.SetStateAction<number>>,
  durationMs: number,
  isPlaying: boolean
) {
  // Clear any timers already running
  clearInterval(progressMsIntervalRef.current as any);

  progressMsIntervalRef.current = setInterval(async () => {
    if (!isPlaying) return;
    // TODO IMPORTANT need to trigger killing of timer if no nowPlaying track is queued up
    // this just ensures pogressMs never exceeds durationMs
    setProgressMs((progressMs: number) => {
      if (durationMs !== 0 && progressMs < durationMs) {
        const updated =
          progressMs + 1000 < durationMs ? progressMs + 1000 : durationMs;
        return updated;
      }
      return progressMs;
    });
  }, 1000);
}

async function syncLocalPlayer(
  source: Models.Synthetic.Services.Service | null,
  nowPlayingTrack: Models.NowPlayingTrack.NowPlayingTrackWithTrackInfo | null,
  isPlaying: boolean,
  spotifyPlayer?: Spotify.Player,
  appleMusicPlayer?: MusicKit.MusicKitInstance
) {
  if (source === Models.Synthetic.Services.Service.Spotify && spotifyPlayer) {
    if (nowPlayingTrack) {
      await DiscoMusicAPI.Player.play(nowPlayingTrack.trackId);
    }
    if (!isPlaying) {
      await spotifyPlayer.pause();
    }
  } else if (
    source === Models.Synthetic.Services.Service.AppleMusic &&
    appleMusicPlayer
  ) {
    appleMusicPlayer.pause();

    if (nowPlayingTrack) {
      await appleMusicPlayer.setQueue({
        items: [],
        song: nowPlayingTrack.appleMusicId,
      });
    }
    if (isPlaying) {
      appleMusicPlayer.play();
    }
  }
}

async function dispatchPlayerSDKAction(
  source: Models.Synthetic.Services.Service | null,
  spotifySDK: Spotify.Player | null,
  spotifyAction: (sdk: Spotify.Player) => void,
  appleMusicSDK: MusicKit.MusicKitInstance | null,
  appleMusicAction: (sdk: MusicKit.MusicKitInstance) => Promise<void>
) {
  switch (source) {
    case null:
      console.error(
        `Missing a player source, unable to dispatch player action.`
      );
      break;
    case Models.Synthetic.Services.Service.Spotify:
      if (!spotifySDK) {
        console.error(
          `Spotify SDK not initialized, unable to dispatch player action.`
        );
        return;
      }
      spotifyAction(spotifySDK);
      break;

    case Models.Synthetic.Services.Service.AppleMusic:
      if (!appleMusicSDK) {
        console.error(
          `Apple Music SDK not initialized, unable to dispatch player action.`
        );
        return;
      }
      await appleMusicAction(appleMusicSDK);
      break;
    default:
      console.error(`Invalid source value, unable to dispatch player action.`);
  }
}

function handleAppBackgrounded(
  initWs: () => void,
  closeWs: () => void,
  keepAlive: boolean
) {
  const { visibilityState } = document;
  if (visibilityState === 'hidden') {
    if (keepAlive) {
      console.log('App has been backgrounded.');
    } else {
      console.log('App has been backgrounded, closing websocket connection.');
      closeWs();
    }
  } else {
    if (keepAlive) {
      console.log('App has been foregrounded.');
    } else {
      console.log(
        'App has been foregrounded, re-establishing websocket connection.'
      );
      initWs();
    }
  }
}
