import styles from './index.module.scss';

export interface Props {
  source: string;
  title: string;
  body: string;
}

export default function Toast({ body }: Props) {
  return <div className={styles.Toast}>{body}</div>;
}
