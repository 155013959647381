import { useState, useContext, createContext, useCallback } from 'react';
import * as uuid from 'uuid';

import ToastRack, {
  ToastNotification,
} from '../components/foundation/ToastRack';

export interface Context {
  toasts: { [id: string]: ToastNotification };
  notify: (toast: Omit<ToastNotification, 'id' | 'createdAt'>) => void;
  removeToast: (id: string) => void;
}

const ToastContext = createContext<Context>({
  toasts: {},
  notify: () => void 0,
  removeToast: () => void 0,
});

export interface ProvideToastsProps {
  children: any;
}

export default function ProvideToasts({ children }: ProvideToastsProps) {
  const context = useProvideToasts();
  return (
    <ToastContext.Provider value={context}>
      <ToastRack toasts={context.toasts} />
      {children}
    </ToastContext.Provider>
  );
}

export const useToast = (): Context => {
  return useContext(ToastContext);
};

function useProvideToasts(): Context {
  const [toasts, setToasts] = useState<{ [id: string]: ToastNotification }>({});

  const notify = useCallback(
    (toast: Omit<ToastNotification, 'id' | 'createdAt'>) => {
      const id = uuid.v4();
      const createdAt = new Date();
      setTimeout(() => {
        setToasts(({ [id]: _toRemove, ...latest }) => latest);
      }, 4000);
      setToasts(latest => ({ ...latest, [id]: { ...toast, id, createdAt } }));
    },
    [setToasts]
  );

  const removeToast = useCallback(
    (id: string) => {
      setToasts(({ [id]: _toRemove, ...latest }) => {
        return latest;
      });
    },
    [setToasts]
  );

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     removeToast(id);
  //   }, 3000); // delay

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [id, removeToast]);

  return {
    toasts,
    notify,
    removeToast,
  };
}
