import * as Models from 'disco-music-api';

import Icon, { IconName } from '../../foundation/Icon';
import Label, { LabelSize } from '../../typography/Label';

import styles from './index.module.scss';

interface Props {
  albumImageUrl: string;
  name: string;
  artists: string[];
  disableHover?: boolean;
}

export function TrackListItem({
  albumImageUrl,
  name,
  artists,
  disableHover,
}: Props) {
  return (
    <div className={`${styles.Track} ${disableHover || styles.TrackHover}`}>
      <div className={styles.TrackAlbumArtWrapper}>
        <Icon
          name={IconName.SampleAlbumArt}
          url={albumImageUrl}
          className={styles.TrackAlbumArt}
        />
      </div>
      <div className={styles.TrackDetails}>
        <Label value={name} size={LabelSize.Small} />
        <Label value={artists.join(', ')} size={LabelSize.ExtraSmall} />
      </div>
    </div>
  );
}
