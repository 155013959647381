export default function MusicPlayerPrevious() {
  return (
    <>
      <path
        stroke="none"
        transform="rotate(-90 77.5 15)"
        fill="#fff"
        d="M26.901923788647 5.2201997807902a3 3 0 0 1 5.1961524227066 0l26.303847577293 45.55960043842a3 3 0 0 1 -2.5980762113533 4.5l-52.607695154587 0a3 3 0 0 1 -2.5980762113533 -4.5"
      />
      <path
        stroke="none"
        transform="rotate(-90 51.75 40.75)"
        fill="#fff"
        d="M26.901923788647 5.2201997807902a3 3 0 0 1 5.1961524227066 0l26.303847577293 45.55960043842a3 3 0 0 1 -2.5980762113533 4.5l-52.607695154587 0a3 3 0 0 1 -2.5980762113533 -4.5"
      />
    </>
  );
}
