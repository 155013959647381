import * as Models from 'disco-music-api';

import List from '../foundation/List';
import { TrackListItem } from './TrackListItem';

import styles from './index.module.scss';

interface Props {
  tracks: {
    [id: string]: Models.Track.Track;
  };
  onClick?: (trackId: string) => Promise<void>;
}
export function TrackList({ tracks, onClick }: Props) {
  return (
    <List
      items={Object.keys(tracks)}
      renderCustomItem={trackId => {
        const track = tracks[trackId];
        if (!track) return <></>;
        return (
          <TrackListItem
            key={trackId}
            albumImageUrl={track.albumImageUrl}
            name={track.name}
            artists={track.artists}
          />
        );
      }}
      onClick={onClick}
      className={styles.TrackList}
    />
  );
}
