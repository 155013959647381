import styles from './index.module.scss';

interface Props {
  name: string;
  onClick?: () => void;
  className?: string;
}
export function Pill({ name, onClick, className }: Props) {
  return (
    <div className={`${styles.Pill} ${className}`} onClick={onClick}>
      <span>{name}</span>
    </div>
  );
}
