import { createPortal } from 'react-dom';

import Toast from './Toast';
import styles from './index.module.scss';

export interface ToastNotification {
  id: string;
  source: string;
  title: string;
  body: string;
  createdAt: Date;
}

interface Props {
  toasts: { [id: string]: ToastNotification };
}

export default function ToastRack({ toasts }: Props) {
  return createPortal(
    <div className={styles.ToastRack}>
      {Object.values(toasts)
        .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
        .map(({ id, source, title, body }) => (
          <Toast key={id} source={source} title={title} body={body} />
        ))}
    </div>,
    document.body
  );
}
