import { Pill } from '../../../components/foundation/Pill';
import { Gutter } from '../../../components/Gutter';

import styles from './index.module.scss';

export enum SearchFilter {
  Songs = 'Songs',
  Albums = 'Albums',
  Artists = 'Artists',
}

interface Props {
  filter: SearchFilter;
  onChange: (filter: SearchFilter) => void;
}

export function Filter({ filter, onChange }: Props) {
  return (
    <Gutter>
      <Pill
        name={SearchFilter.Songs}
        onClick={() => {
          onChange(SearchFilter.Songs);
        }}
        className={filter === SearchFilter.Songs ? styles.Active : void 0}
      />
      <Pill
        name={SearchFilter.Albums}
        onClick={() => {
          onChange(SearchFilter.Albums);
        }}
        className={filter === SearchFilter.Albums ? styles.Active : void 0}
      />
      <Pill
        name={SearchFilter.Artists}
        onClick={() => {
          onChange(SearchFilter.Artists);
        }}
        className={filter === SearchFilter.Artists ? styles.Active : void 0}
      />
    </Gutter>
  );
}
