import React from 'react';

import AppleMusic from './assets/AppleMusic';
import MusicPlayerPlay from './assets/MusicPlayerPlay';
import MusicPlayerPause from './assets/MusicPlayerPause';
import MusicPlayerNext from './assets/MusicPlayerNext';
import MusicPlayerPrevious from './assets/MusicPlayerPrevious';
import PartyDrink from './assets/PartyDrink';
import Settings from './assets/Settings';
import SidebarParties from './assets/SidebarParties';
import SidebarLibrary from './assets/SidebarLibrary';
import SidebarLogout from './assets/SidebarLogout';
import SidebarSearch from './assets/SidebarSearch';
import Spotify from './assets/Spotify';
import CollapsibleOpen from './assets/CollapsibleOpen';
import CollapsibleClosed from './assets/CollapsibleClosed';
import NavBarMenu from './assets/NavBarMenu';
import Close from './assets/Close';
import PlayerShuffle from './assets/PlayerShuffle';
import PlayerRepeatAll from './assets/PlayerRepeatAll';
import PlayerRepeatCurrent from './assets/PlayerRepeatCurrent';
import Choices from './assets/Choices';
import Speaker from './assets/Speaker';

import styles from './index.module.scss';

export enum SVGName {
  AppleMusic = 'apple_music',
  // SidebarExplore = 'sidebar_explore',
  // SidebarGenres = 'sidebar_genres',
  // SidebarAlbums = 'sidebar_albums',
  // SidebarArtists = 'sidebar_artists',
  // SidebarRadio = 'sidebar_radio',
  SidebarParties = 'sidebar_parties',
  SidebarLibrary = 'sidebar_library',
  SidebarLogout = 'sidebar_log_out',
  SidebarSearch = 'sidebar_search',
  SidebarSettings = 'sidebar_settings',
  Spotify = 'spotify',
  Settings = 'settings',
  CollapsibleOpen = 'collapsible_open',
  CollapsibleClosed = 'collapsible_closed',
  PartyDrink = 'party_drink',
  NavBarMenu = 'nav_bar_menu',
  Close = 'close',
  PlayerShuffle = 'player_shuffle',
  PlayerRepeatAll = 'player_repeat_all',
  PlayerRepeatCurrent = 'player_repeat_current',
  MusicPlayerPlay = 'music_player_play',
  MusicPlayerPause = 'music_player_pause',
  MusicPlayerPrevious = 'music_player_previous',
  MusicPlayerNext = 'music_player_next',
  Choices = 'choices',
  Speaker = 'speaker',
  // // TODO remove maybe
  // UserPlaceholder = 'user_placeholder',
  // SampleAlbumArt = 'sample_album_art',
}

export enum SVGSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

interface Props {
  name: SVGName;
  size: SVGSize;
  className?: string;
  handleClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
  svgOptions?: { [key: string]: string };
  fill?: string;
  gradient?: boolean;
  disabled?: boolean;
}

export default function IconSVG(props: Props) {
  const {
    name,
    size,
    className,
    handleClick,
    svgOptions,
    fill,
    gradient,
    disabled,
  } = props;
  return (
    <Wrapper
      name={name}
      className={`${styles.IconSVG} ${size ? styles[size] : ''} ${
        gradient ? styles.Gradient : ''
      } ${className} ${disabled ? styles.Disabled : ''}`}
      handleClick={disabled ? void 0 : handleClick}
      options={svgOptions}
      fill={gradient ? 'url(#gradient-fill)' : fill ?? undefined}
    >
      {gradient ? (
        <linearGradient id="gradient-fill" x1="100%" y1="0" x2="0" y2="100%">
          <stop stopColor={'#5b99e5'} />
          <stop offset="0.75" stopColor={'#c0117b'} />
        </linearGradient>
      ) : undefined}
    </Wrapper>
  );
}

interface WrapperProps {
  name: SVGName;
  className: string;
  handleClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
  fill?: string;
  options?: { [key: string]: string };
  children?: JSX.Element;
}

function Wrapper(props: WrapperProps) {
  const { name, className, handleClick, fill, options = {}, children } = props;
  const [SvgElement, svgOptions] = _getSVG(name);
  return (
    <svg
      id={name}
      data-name={name}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 125 125"
      className={className}
      onClick={handleClick}
      fill={fill || 'white'}
      {...svgOptions}
      {...options}
    >
      <SvgElement />
      <defs>{children}</defs>
    </svg>
  );
}

function _getSVG(
  name: SVGName
): [() => JSX.Element, { [key: string]: string }] {
  switch (name) {
    case SVGName.AppleMusic:
      return [AppleMusic, {}];
    case SVGName.PartyDrink:
      return [PartyDrink, {}];
    case SVGName.SidebarParties:
      return [SidebarParties, {}];
    case SVGName.SidebarLibrary:
      return [SidebarLibrary, {}];
    case SVGName.SidebarLogout:
      return [SidebarLogout, {}];
    case SVGName.SidebarSearch:
      return [SidebarSearch, {}];
    case SVGName.SidebarSettings:
    case SVGName.Settings:
      return [Settings, {}];
    case SVGName.Spotify:
      return [Spotify, { viewBox: '0 0 1333 1333' }];
    case SVGName.CollapsibleClosed:
      return [CollapsibleClosed, {}];
    case SVGName.CollapsibleOpen:
      return [CollapsibleOpen, {}];
    case SVGName.NavBarMenu:
      return [NavBarMenu, {}];
    case SVGName.Close:
      return [Close, {}];
    case SVGName.PlayerShuffle:
      return [PlayerShuffle, {}];
    case SVGName.PlayerRepeatAll:
      return [PlayerRepeatAll, {}];
    case SVGName.PlayerRepeatCurrent:
      return [PlayerRepeatCurrent, {}];
    case SVGName.MusicPlayerPlay:
      return [MusicPlayerPlay, {}];
    case SVGName.MusicPlayerPause:
      return [MusicPlayerPause, {}];
    case SVGName.MusicPlayerPrevious:
      return [MusicPlayerPrevious, {}];
    case SVGName.MusicPlayerNext:
      return [MusicPlayerNext, {}];
    case SVGName.Choices:
      return [Choices, {}];
    case SVGName.Speaker:
      return [Speaker, {}];
  }
}
