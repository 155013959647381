import { useState } from 'react';

import { CustomHeaderPageProps, useCustomHeaderEffect } from '../../pages';
import Playlists from './Playlists';
import Artists from './Artists';
import Albums from './Albums';

import styles from './index.module.scss';

interface Props extends CustomHeaderPageProps {}

const HEADER_KEY_BASE = `PAGES_LIBRARY_HEADER_ITEM`;
const HEADER_VALUES = ['Playlists', 'Artists', 'Albums'];
function constructHeaderKey(index: number, value: string): string {
  return `${HEADER_KEY_BASE}_${index}_${value}`;
}

export default function LibraryPage(props: Props) {
  const [activeItem, setActiveItem] = useState(0);

  const { setCustomNavBarHeader } = props;
  useCustomHeaderEffect(
    setCustomNavBarHeader,
    <CustomHeader activeItem={activeItem} setActiveItem={setActiveItem} />,
    [activeItem]
  );

  let pageContent;
  switch (activeItem) {
    case 0:
      pageContent = <Playlists />;
      break;
    case 1:
      pageContent = <Artists />;
      break;
    case 2:
      pageContent = <Albums />;
      break;
    default:
      break;
  }
  return (
    <div className={styles.LibraryPage}>
      <h2>{HEADER_VALUES[activeItem]}</h2>
      {pageContent}
    </div>
  );
}

interface CustomHeaderProps {
  activeItem: number;
  setActiveItem: (item: number) => void;
}
function CustomHeader(props: CustomHeaderProps): JSX.Element {
  const { activeItem, setActiveItem } = props;
  return (
    <div className={styles.CustomHeader}>
      {HEADER_VALUES.map((value, i) => {
        const key = constructHeaderKey(i, value);
        return (
          <span
            key={key}
            className={activeItem === i ? styles.HeaderItemActive : ''}
            onClick={() => setActiveItem(i)}
          >
            {value}
          </span>
        );
      })}
    </div>
  );
}
