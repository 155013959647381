import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as Models from 'disco-music-api';
import 'react-phone-number-input/style.css';

import * as Auth from '../../context/auth';
import Alert from '../../components/foundation/Alert';
import Button from '../../components/foundation/Button';

import styles from './index.module.scss';

export default function Verify() {
  const [verificationCode, setVerificationCode] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const { username } = useParams<{ username: string }>();

  const authContext = Auth.useAuth();
  let history = useHistory();
  let location = useLocation<{ referrer: string }>();
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => () => setIsMounted(false));

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!verificationCode) {
      setValidationErrors(['Invalid verification code']);
      return;
    }

    let { referrer } = location.state || { referrer: '/parties' };
    try {
      await authContext.login(username, verificationCode);
      // authContext.refresh();
      // devicesContext.refresh();
      // history.replace(referrer);
      console.log("LOGIN SUCCEEDED");
      
      history.push('/parties')
    } catch (error) {
      console.error(error);
      if (isMounted) setValidationErrors([error.message]);
      return;
    }
  };

  const handleRequestNewCode = async () => {
    try {
      await authContext.requestVerification(
        username,
        Models.Synthetic.Auth.VerificationMethod.SMS
      );
    } catch (error) {
      console.error(error);
      setValidationErrors([error.message]);
      return;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.Content}>
        <h2>Verify</h2>
        <p>
          Please enter the 6-digit code sent to the phone number associated with
          this account.
        </p>

        <form onSubmit={handleSubmit}>
          {validationErrors.length ? (
            <Alert>
              <>
                {validationErrors.map((v, i) => (
                  <b key={i}>{v}</b>
                ))}
              </>
            </Alert>
          ) : null}

          <label>Verification code</label>
          <input
            placeholder="Enter 6-digit verification code"
            type={'text'}
            value={verificationCode || ''}
            onChange={e => setVerificationCode(e.target.value)}
            className={styles.VerificationCodeInput}
          />

          <br />

          <div className={styles.Footer}>
            <Button name={'Submit'} type={'submit'} />
            <span>
              Need a new code?{' '}
              <span
                onClick={handleRequestNewCode}
                className={styles.RequestNewCode}
              >
                Request
              </span>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}
