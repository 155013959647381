import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';

import {
  usePrimaryNavigationControl,
  PrimaryNavigationControl,
} from '../../context/primaryNavigationControl';

import List from '../foundation/List';
import IconSVG, { SVGName, SVGSize } from '../foundation/IconSVG';
import Label, { LabelSize } from '../typography/Label';

import styles from './index.module.scss';
import { useAuth } from '../../context/auth';

enum SideBarSection {
  Main = 'MAIN',
  General = 'GENERAL',
}

const SIDE_BAR_LIST_ITEMS = {
  [SideBarSection.Main]: {
    title: 'Main',
    items: ['Parties', 'Library', 'Search'],
  },
  [SideBarSection.General]: {
    title: 'General',
    items: ['Settings', 'Log Out'],
  },
};

const LOG_OUT_LIST_ITEM =
  SIDE_BAR_LIST_ITEMS[SideBarSection.General].items[
    SIDE_BAR_LIST_ITEMS[SideBarSection.General].items.length - 1
  ];

function sectionReverseLookup(item: string): [SideBarSection, number] {
  const normalizedItem = `${item.charAt(0).toUpperCase()}${item
    .slice(1)
    .toLowerCase()}`;
  if (
    SIDE_BAR_LIST_ITEMS[SideBarSection.General].items.includes(normalizedItem)
  ) {
    return [
      SideBarSection.General,
      SIDE_BAR_LIST_ITEMS[SideBarSection.General].items.indexOf(normalizedItem),
    ];
  }
  return [
    SideBarSection.Main,
    SIDE_BAR_LIST_ITEMS[SideBarSection.Main].items.indexOf(normalizedItem),
  ];
}

interface Props {}
// TODO enable clikcing anywhere to exit
export default function SideBar(props: Props) {
  const {
    activeControl,
    setActiveControl,
    isSideBarOpen,
    toggleSideBar,
  } = usePrimaryNavigationControl();
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();

  let selectedItemFromPath = location.pathname.slice(1);
  const [section, activeItemIndex] = sectionReverseLookup(selectedItemFromPath);
  const [sideBarSection, setSideBarSection] = useState<SideBarSection>(section);

  // TODO extract
  const renderCustomItem = (item: string, isActive: boolean) => {
    return (
      <div className={styles.CustomItem}>
        <IconSVG
          name={`sidebar_${item.split(' ').join('_').toLowerCase()}` as SVGName}
          size={SVGSize.Small}
          className={styles.SidebarListItemIcon}
          gradient={isActive}
        />
        <Label
          value={item}
          size={LabelSize.Small}
          className={styles.SidebarListItemLabel}
        />
      </div>
    );
  };

  const handleClick = (section: SideBarSection) => (item: string) => {
    if (item === LOG_OUT_LIST_ITEM) {
      auth.logout();
      return;
    }
    history.push(`/${item.toLowerCase()}`);
    setActiveControl(PrimaryNavigationControl.Sidebar);
    setSideBarSection(section);
    toggleSideBar();
  };

  /* Smaller than phablet (also point before grid becomes active) */
  const isMobile = useMediaQuery({
    query: '(max-width: 550px)',
  });

  const listIndexIsValid = activeItemIndex !== -1;
  const isActive = activeControl === PrimaryNavigationControl.Sidebar;
  return (
    <div
      className={`${styles.two} ${styles.columns} ${styles.SideBar} ${
        isMobile && isSideBarOpen ? '' : styles.SideBarClosed
      }`}
    >
      <div className={styles.SideBarHeader}>
        <h4 className={styles.CompanyLabel}>DisCo</h4>
        {isMobile && (
          <IconSVG
            name={SVGName.Close}
            size={SVGSize.Medium}
            handleClick={toggleSideBar}
            gradient
          />
        )}
      </div>

      <div className={styles.Spacer}></div>

      <List
        title={SIDE_BAR_LIST_ITEMS[SideBarSection.Main].title}
        items={SIDE_BAR_LIST_ITEMS[SideBarSection.Main].items}
        activeItemIndex={
          sideBarSection === SideBarSection.Main && listIndexIsValid
            ? activeItemIndex
            : undefined
        }
        renderCustomItem={renderCustomItem}
        onClick={handleClick(SideBarSection.Main)}
        highlightActiveEnabled={isActive}
      />

      <List
        title={SIDE_BAR_LIST_ITEMS[SideBarSection.General].title}
        items={SIDE_BAR_LIST_ITEMS[SideBarSection.General].items}
        activeItemIndex={
          sideBarSection === SideBarSection.General && listIndexIsValid
            ? activeItemIndex
            : undefined
        }
        renderCustomItem={renderCustomItem}
        onClick={handleClick(SideBarSection.General)}
        highlightActiveEnabled={isActive}
      />
    </div>
  );
}
