import { useEffect, useMemo, useRef, useState } from 'react';
import * as Models from 'disco-music-api';
import debounce from 'lodash/debounce';

import styles from './index.module.scss';
import { usePlayer } from '../../context/player';
import { MediaItem, MediaList } from '../../components/MediaList';
import Label, { LabelSize } from '../../components/typography/Label';
import SegmentedControl from '../../components/foundation/SegmentedControl';
import { Filter, SearchFilter } from './Filter';
import { useHistory } from 'react-router';

const DEBONUCE_MS = 500;

enum QuerySource {
  Library = 'Library',
  Catalogue = 'Catalogue',
}

export default function SearchPage() {
  const history = useHistory();

  const [player] = usePlayer();
  // const [querySuggestions, setQuerySuggestions] = useState<string[]>([]);
  const [tracks, setTracks] = useState<{
    [trackId: string]: Models.Track.Track;
  }>({});
  const [albums, setAlbums] = useState<{
    [trackId: string]: Models.Album.Album;
  }>({});
  const [artists, setArtists] = useState<{
    [trackId: string]: Models.Artist.Artist;
  }>({});
  const [querySource, setQuerySource] = useState(QuerySource.Catalogue);
  const [filter, setFilter] = useState(SearchFilter.Songs);

  useEffect(() => {
    setTracks({});
  }, [player?.source]);

  // debounced search + unmounted handling
  const search = async (incomingQuery: string) => {
    if (!incomingQuery) {
      setTracks({})
      setAlbums({})
      setArtists({})
      return
    }

    const { tracks, albums, artists } = (await player?.search(incomingQuery)) || {};

    setTracks(
      (tracks || []).reduce((all, track) => ({ ...all, [track.id]: track }), {})
    );
    setAlbums(
      (albums || []).reduce((all, album) => ({ ...all, [album.id]: album }), {})
    );
    setArtists(
      (artists || []).reduce(
        (all, artist) => ({ ...all, [artist.id]: artist }),
        {}
      )
    );
  }

  const searchDebounced = useMemo(() =>
    debounce(search, DEBONUCE_MS),
    []
  );

  useEffect(() => {
    return () => {
      searchDebounced.cancel();
    };
  });

  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const incomingQuery = event.target.value
    await searchDebounced(incomingQuery)
  }

  return (
    <div className={styles.SearchPage}>
      <div className={styles.Header}>
        <Label value={'Search'} size={LabelSize.Large} />
        <div className={styles.SearchBar}>
          <input
            type="search"
            placeholder="Artists, Songs, Lyrics, and More"
            className={styles.SearchInput}
            onChange={handleSearch}
          />
        </div>
      </div>

      <div className={styles.SegmentedControl}>
        <SegmentedControl
          name="search-filter-segmented-control"
          callback={setQuerySource}
          defaultIndex={0}
          controlRef={useRef()}
          segments={[
            {
              label: QuerySource.Catalogue,
              value: QuerySource.Catalogue,
              ref: useRef(),
            },
            {
              label: QuerySource.Library,
              value: QuerySource.Library,
              ref: useRef(),
            },
          ]}
        />
      </div>

      <Filter filter={filter} onChange={setFilter} />

      <div className={styles.Results}>
        <MediaList
          mediaItems={selectMediaItems(
            filter,
            Object.values(tracks),
            Object.values(albums),
            Object.values(artists)
          )}
          onClick={async id => {
            switch (filter) {
              case SearchFilter.Songs:
                const track = tracks[id];
                player?.enqueue(id, track);
                break;
              case SearchFilter.Albums:
                history.push(`/albums/${id}`);
                break;
              case SearchFilter.Artists:
                history.push(`/artists/${id}`);
                break;
            }
          }}
        />
      </div>
    </div>
  );
}

function selectMediaItems(
  filter: SearchFilter,
  tracks: Models.Track.Track[],
  albums: Models.Album.Album[],
  artists: Models.Artist.Artist[]
): MediaItem[] {
  switch (filter) {
    case SearchFilter.Songs:
      return tracks.map(({ id, albumImageUrl, name, artists }) => ({
        id,
        imageUrl: albumImageUrl,
        name,
        subTexts: artists,
      }));
    case SearchFilter.Albums:
      return albums.map(({ id, albumImageUrl, name, artists }) => ({
        id,
        imageUrl: albumImageUrl,
        name,
        subTexts: artists,
      }));
    case SearchFilter.Artists:
      return artists.map(({ id, artistImageUrl, name }) => ({
        id,
        imageUrl: artistImageUrl,
        name,
        subTexts: ['Artist'],
      }));
  }
}
