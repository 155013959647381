import { useEffect, useState } from 'react';
import DiscoMusicAPI from 'disco-music-api';
import * as Models from 'disco-music-api';
import queryString from 'query-string';

import IconSVG, { SVGName, SVGSize } from '../../components/foundation/IconSVG';
import Toggle from '../../components/foundation/Toggle';
import { usePlayer } from '../../context/player';

import styles from './index.module.scss';
import { useMediaQuery } from 'react-responsive';
import Modal from '../../components/foundation/Modal';
import { useHistory, useLocation, useParams } from 'react-router';
import { useQuery } from '../../hooks/useQuery';

enum Setting {
  General = 'General',
  Services = 'Services',
}

// interface QueryParams {
//   setting?: Setting
//   service?: Models.Synthetic.Services.Service,
//   code?: string
//   state?: string
// }

export default function Settings() {
  const [player] = usePlayer();
  const query = useQuery();

  /* Smaller than phablet (also point before grid becomes active) */
  const isMobile = useMediaQuery({
    query: '(max-width: 550px)',
  });

  const [activeSetting, setActiveSetting] = useState<Setting | null>(null);

  const setting = query.get('setting');
  useEffect(() => {
    if (!setting || !Object.values(Setting).includes(setting as any)) return;
    setActiveSetting(setting as Setting);
  }, [setting]);

  return (
    <div className={styles.SettingsPage}>
      <div className={styles.Header}>
        <h2>Settings</h2>
        {isMobile && activeSetting && (
          <h2 onClick={() => setActiveSetting(null)}>←</h2>
        )}
      </div>
      <div className={styles.Settings}>
        <SettingsList
          items={[Setting.General, Setting.Services]}
          activeSetting={activeSetting}
          setActiveSetting={setActiveSetting}
        >
          {(item: Setting) => <SettingDetail item={item} />}
        </SettingsList>
      </div>
    </div>
  );
}

interface SettingsListProps {
  items: Setting[];
  activeSetting: Setting | null;
  setActiveSetting: (setting: Setting | null) => void;
  children: (item: Setting) => JSX.Element;
}
function SettingsList({
  items,
  activeSetting,
  setActiveSetting,
  children,
}: SettingsListProps) {
  /* Smaller than phablet (also point before grid becomes active) */
  const isMobile = useMediaQuery({
    query: '(max-width: 550px)',
  });

  const toggle = (selectedSetting: Setting) => {
    if (selectedSetting === activeSetting) {
      setActiveSetting(null);
    } else {
      setActiveSetting(selectedSetting);
    }
  };

  return (
    <>
      {(!isMobile || !activeSetting) && (
        <div className={styles.SettingsList}>
          {items.map(item => (
            <SettingsItem
              name={item}
              toggle={() => toggle(item)}
              isActive={activeSetting === item}
            />
          ))}
          {/* <div className={styles.SettingsItem}>
    <IconSVG
      name={SVGName.SidebarLibrary}
      size={SVGSize.Small}
      className={styles.SettingsItemIcon}
    />
    <span>Account</span>
  </div>

  <div className={styles.SettingsItem}>
    <IconSVG
      name={SVGName.SidebarLibrary}
      size={SVGSize.Small}
      className={styles.SettingsItemIcon}
    />
    <span>Services</span>
  </div> */}
        </div>
      )}
      {activeSetting && children(activeSetting)}
    </>
  );
}

interface SettingsItemProps {
  name: string;
  toggle: () => void;
  isActive: boolean;
}
function SettingsItem(props: SettingsItemProps) {
  const { name, toggle, isActive } = props;
  return (
    <div
      className={[styles.SettingsListItem, isActive ? styles.Active : ''].join(
        ' '
      )}
      onClick={toggle}
    >
      <div>
        <IconSVG
          name={SVGName.SidebarLibrary}
          size={SVGSize.Small}
          className={styles.SettingsItemIcon}
        />
      </div>
      <span>{name}</span>
    </div>
  );
}

interface SettingDetailProps {
  item: Setting;
}
function SettingDetail(props: SettingDetailProps) {
  const { item } = props;
  switch (item) {
    case Setting.Services:
      return <SettingDetailServices />;
    case Setting.General:
      return (
        <div className={styles.SettingDetails}>
          <div className={styles.SettingsDetailItem}>
            <IconSVG
              name={SVGName.SidebarLibrary}
              size={SVGSize.Small}
              className={styles.SettingsItemIcon}
            />
            <span>Profile</span>
          </div>
          {/* <hr className={styles.Rounded}></hr> */}
          {/* <span>Apple Music</span> */}
        </div>
      );
  }
}

function SettingDetailServices() {
  const history = useHistory();
  // TODO: this rerenders when it shouldn't... find what's causing it and fix it!!
  const [player, serviceStatuses] = usePlayer();
  console.log(serviceStatuses);
  console.log(
    player?.isAuthorized(Models.Synthetic.Services.Service.AppleMusic) || false
  );

  // const [showServicesModal, setShowServicesModal] = useState(false);

  function serviceStatusToUserText(
    status: Models.Synthetic.Services.ServiceStatus,
    forceIsActive: boolean = false
  ): string {
    if (
      forceIsActive ||
      status === Models.Synthetic.Services.ServiceStatus.Active
    ) {
      return 'Sign Out';
    }
    return 'Sign In';
  }

  // const hasAuthorizedServices = player
  //   ? Object.values(player.authorizedServices).every(Boolean)
  //   : false;

  return (
    <>
      <div className={styles.SettingDetails}>
        <div className={styles.SettingsDetailItem}>
          <div className={styles.SettingHeader}>
            <div>
              <IconSVG
                name={SVGName.Spotify}
                size={SVGSize.Small}
                className={styles.SettingsItemIcon}
              />
            </div>
            <span>Spotify</span>
          </div>

          <div>
            <div className={styles.SettingItemToggle}>
              <span className={styles.Auth}>
                {serviceStatusToUserText(serviceStatuses.SPOTIFY)}
              </span>
              <Toggle
                isChecked={
                  serviceStatuses.SPOTIFY ===
                  Models.Synthetic.Services.ServiceStatus.Active
                }
                handleClick={async () => {
                  if (
                    serviceStatuses.SPOTIFY ===
                    Models.Synthetic.Services.ServiceStatus.Active
                  ) {
                    try {
                      await player?.unauthorize(
                        Models.Synthetic.Services.Service.Spotify
                      );
                      return;
                    } catch (error) {
                      console.log(error);
                    }
                  }
                  try {
                    const redirectURI = await player?.authorize(
                      Models.Synthetic.Services.Service.Spotify
                    );
                    if (redirectURI) {
                      window.location.assign(redirectURI);
                    } else {
                      console.error(
                        'Uh Oh! No redirect URI was received after authentication'
                      );
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.SettingsDetailItem}>
          <div className={styles.SettingHeader}>
            <div>
              <IconSVG
                name={SVGName.AppleMusic}
                size={SVGSize.Small}
                // className={styles.SettingsItemIcon}
              />
            </div>
            <span>Apple Music</span>
          </div>

          <div>
            <div className={styles.SettingItemToggle}>
              <span className={styles.Auth}>
                {serviceStatusToUserText(
                  serviceStatuses.APPLE_MUSIC,
                  player?.isAuthorized(
                    Models.Synthetic.Services.Service.AppleMusic
                  )
                )}
              </span>
              <Toggle
                isChecked={
                  player?.isAuthorized(
                    Models.Synthetic.Services.Service.AppleMusic
                  ) || false
                }
                handleClick={async () => {
                  if (
                    player?.isAuthorized(
                      Models.Synthetic.Services.Service.AppleMusic
                    )
                  ) {
                    try {
                      await player?.unauthorize(
                        Models.Synthetic.Services.Service.AppleMusic
                      );
                    } catch (error) {
                      console.log(error);
                    }
                    return;
                  }

                  try {
                    console.log('AUTHOIZING APPLE MUSIC');

                    await player?.authorize(
                      Models.Synthetic.Services.Service.AppleMusic
                    );
                  } catch (error) {
                    console.log(error);
                  }
                }}
              />
            </div>
          </div>
        </div>
        {/* <hr className={styles.Rounded}></hr> */}
        {/* <span>Apple Music</span> */}
      </div>
    </>
  );
}
