import DiscoMusicAPI from 'disco-music-api';
import * as Models from 'disco-music-api';
import Modal from '../foundation/Modal';
import { useCallback, useEffect, useState } from 'react';
import Button from '../foundation/Button';

export enum ModalAction {
  Unauthenticated = 'UNAUTHENTICATED',
  Authenticated = 'AUTHENTICATED',
  SessionExpired = 'SESSION_EXPIRED',
}

export interface AuthorizedServices {
  [Models.Synthetic.Services.Service.Spotify]: boolean;
  [Models.Synthetic.Services.Service.AppleMusic]: boolean;
}

export interface Props {
  action: ModalAction;
  subject: Models.Synthetic.Services.Service;
  authorizedServices: AuthorizedServices;
  onDismiss: (
    source: Models.Synthetic.Services.Service | null | undefined
  ) => Promise<void>;
}
export type ServicesModalPayload = Omit<
  Props,
  'onDismiss' | 'authorizedServices'
>;

export default function ServicesModal({
  action,
  subject,
  authorizedServices,
  onDismiss,
}: Props) {
  // const [
  //   source,
  //   setSource,
  // ] = useState<Models.Synthetic.Services.Service | null>(null);

  const authenticated = (Object.keys(
    authorizedServices
  ) as Models.Synthetic.Services.Service[]).filter(
    service => authorizedServices[service]
  );

  const onDismissWrapper = async (
    sourceOverride?: Models.Synthetic.Services.Service | null | undefined
  ) => {
    await onDismiss(sourceOverride);
  };

  return (
    <Modal onToggle={onDismissWrapper}>
      <>
        <Header action={action} subject={subject} />
        <Body
          action={action}
          subject={subject}
          authenticatedServices={authenticated}
          onDismiss={onDismissWrapper}
        />
      </>
    </Modal>
  );
}

function Header({
  action,
  subject,
}: {
  action: ModalAction;
  subject: Models.Synthetic.Services.Service;
}) {
  return (
    <h3>
      {(action === ModalAction.Authenticated
        ? 'Logged in with '
        : 'Logged out of ') + subject}
    </h3>
  );
}

function Body({
  action,
  subject,
  authenticatedServices,
  onDismiss,
}: {
  action: ModalAction;
  subject: Models.Synthetic.Services.Service;
  authenticatedServices: Models.Synthetic.Services.Service[];
  onDismiss: (
    sourceOverride?: Models.Synthetic.Services.Service | null
  ) => Promise<void>;
}) {
  let innards: JSX.Element;
  let sourceOverride: Models.Synthetic.Services.Service | null | undefined;
  let ctaText = 'Confirm';

  if (action === ModalAction.Authenticated) {
    if (authenticatedServices.length > 1) {
      innards = (
        <>
          <p>Would you like to set your player's source to use {subject}?</p>
          <p>Other available services:</p>
          {authenticatedServices.map(service => (
            // <p onClick={}>- {service}</p>
            <>
              <span
                // style={
                //   player?.source === Models.Synthetic.Services.Service.Spotify
                //     ? { backgroundColor: 'green' }
                //     : {}
                // }
                onClick={() => onDismiss(service)}
              >
                {service}
              </span>
              <br />
            </>
          ))}
        </>
      );
      ctaText = 'Dismiss';
    } else {
      innards = (
        <p>Setting your player's source to use {authenticatedServices[0]}.</p>
      );
      sourceOverride = authenticatedServices[0];
    }
  } else {
    if (authenticatedServices.length > 1) {
      innards = (
        <>
          <p>Please select a service to set as your player's source:</p>;
          {authenticatedServices.map(service => (
            <p onClick={() => onDismiss(service)}>- {service}</p>
          ))}
        </>
      );
    } else if (!authenticatedServices.length) {
      innards = (
        <p>
          Please authenticate with a service to start using the music player.
        </p>
      );
      sourceOverride = null;
    } else {
      innards = (
        <p>Setting your player's source to use {authenticatedServices[0]}.</p>
      );
      sourceOverride = authenticatedServices[0];
    }
  }

  return (
    <>
      {innards}
      <br />
      <Button
        name={ctaText}
        handleClick={() => {
          console.log('sourceOverride:', sourceOverride);
          console.log('action:', action);
          console.log('subject:', subject);

          onDismiss(sourceOverride);
        }}
      />
    </>
  );
}

// function title(action: ModalAction): string {
//   if (action === ModalAction.Authenticated) {
//     return 'Please select a service to authenticate with';
//   }
// }
