import styles from './index.module.scss';

export enum LabelSize {
  ExtraSmall = 'XSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

interface Props {
  value: string;
  size: LabelSize;
  pad?: boolean;
  className?: string;
}

export default function Label(props: Props) {
  const { value, size, pad, className } = props;
  return (
    <span
      className={`${styles.Label} ${styles[size]} ${pad && styles.Padding} ${
        className || ''
      }`}
    >
      {value}
    </span>
  );
}
