import { useState } from 'react';

import Label, { LabelSize } from '../../typography/Label';

import styles from './index.module.scss';

interface Props {
  title?: string;
  items: string[];
  activeItemIndex?: number;
  highlightActiveEnabled?: boolean;
  onClick?: (item: string) => void;
  renderCustomItem?: (item: string, isActive: boolean) => JSX.Element;
  className?: string;
}

const LIST_ITEM_KEY = 'list-item';

export default function List(props: Props) {
  const {
    title,
    items,
    activeItemIndex,
    highlightActiveEnabled = false,
    onClick,
    renderCustomItem,
    className,
  } = props;
  const [activeListItemIndex, setActiveListItemIndex] = useState<number | null>(
    activeItemIndex ?? null
  );

  const renderItem = (item: string, index: number) => {
    const isActive = highlightActiveEnabled && index === activeListItemIndex;
    const handleClick = () => {
      setActiveListItemIndex(index);
      if (onClick) {
        onClick(item);
      }
    };
    const key = `${LIST_ITEM_KEY}_${item}_${index}`;
    return (
      <li key={key} className={styles.ListItem} onClick={handleClick}>
        {renderCustomItem ? (
          renderCustomItem(item, isActive)
        ) : (
          <Label
            value={item}
            size={LabelSize.Small}
            className={styles.ListItemLabel}
          />
        )}
        {isActive && <div className={styles.ListItemHighlighted} />}
      </li>
    );
  };

  const toRender = (
    <ul className={`${styles.RawList} ${!title ? className : ''}`}>
      {items.map(renderItem)}
    </ul>
  );
  if (!title) return toRender;
  return (
    <div className={`${styles.List} ${className || ''}`}>
      <Label value={title} size={LabelSize.Small} />
      {toRender}
    </div>
  );
}
