import styles from './index.module.scss';

export enum Layout {
  Vertical = 'LayoutVertical',
  Horizontal = 'LayoutHorizontal',
}

interface CardProps {
  children: JSX.Element | JSX.Element[];
  layout?: Layout;
}

export default function Card(props: CardProps) {
  const { children, layout = Layout.Vertical } = props;
  return <div className={`${styles.Card} ${layout}`}>{children}</div>;
}
