import { useState, useContext, createContext } from 'react';

// ServicesContext: queries for all services a user has signed in with on our platform.
// Any active services will have their token passed down to children components / our
// entire application.

export enum PrimaryNavigationControl {
  Sidebar = 'SIDEBAR',
  Player = 'PLAYER',
}

export interface PrimaryNavigationControlContext {
  activeControl: PrimaryNavigationControl;
  isSideBarOpen: boolean;
  setActiveControl: (destination: PrimaryNavigationControl) => void;
  toggleSideBar: () => void;
}

const INITIAL_CONTEXT_VALUE: PrimaryNavigationControlContext = {
  activeControl: PrimaryNavigationControl.Sidebar,
  isSideBarOpen: false,
  setActiveControl: (_: PrimaryNavigationControl) => void 0,
  toggleSideBar: () => void 0,
};
const servicesContext = createContext<PrimaryNavigationControlContext>(
  INITIAL_CONTEXT_VALUE
);

export interface ProvideContextProps {
  children: any;
}

// Provider component that wraps your app and makes services object available
// to any child component that calls useServices()
export default function ProvideContext({ children }: ProvideContextProps) {
  const activeControl = useProvideContext();
  return (
    <servicesContext.Provider value={activeControl}>
      {children}
    </servicesContext.Provider>
  );
}

// Hook for child components to get the auth object and re-render when
// it changes
export const usePrimaryNavigationControl = (): PrimaryNavigationControlContext => {
  return useContext(servicesContext);
};

// Provider hook that creates auth object and handles state
function useProvideContext(): PrimaryNavigationControlContext {
  const [activeControl, setActiveControl] = useState<PrimaryNavigationControl>(
    PrimaryNavigationControl.Sidebar
  );
  const [isSideBarOpen, setisSideBarOpen] = useState<boolean>(false);

  // Return the user object and auth methods
  return {
    activeControl,
    isSideBarOpen,
    setActiveControl: (destination: PrimaryNavigationControl) =>
      setActiveControl(destination),
    toggleSideBar: () => setisSideBarOpen(!isSideBarOpen),
  };
}
