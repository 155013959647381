import { useState } from 'react';

import Label, { LabelSize } from '../../typography/Label';
import Icon, { IconName } from '../../foundation/Icon';
import IconSVG, { SVGName, SVGSize } from '../../foundation/IconSVG';
import { MusicPlayer } from '../../../context/player';
import usePolling from '../../../effects/polling';
import { useToast } from '../../../context/toast';
import DefaultAlbumArt from '../../../assets/default_album_art.png';
import styles from './index.module.scss';

export interface NowPlayingProps {
  player: MusicPlayer;
}
export default function NowPlaying({ player }: NowPlayingProps) {
  const {
    currentTrack,
    isPaused,
    progressMs,
    setProgressMs,
    scrubProgressMs,
    togglePlay,
    partyId,
    isPartyHost,
  } = player;

  const disableControls = partyId ? !isPartyHost : false;

  const { notify } = useToast();
  const trackLength = currentTrack?.playbackDurationMs || 0;
  const progressSeconds = Math.floor(progressMs / 1000);
  const durationSeconds = Math.floor((trackLength || 0) / 1000);

  const currentPercentage = durationSeconds
    ? `${(progressSeconds / durationSeconds) * 100}%`
    : '0%';
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
  `;

  return (
    <div className={styles.NowPlaying}>
      <div className={styles.NowPlayingAlbumArtWrapper}>
        <Icon
          name={IconName.SampleAlbumArt}
          url={player?.currentTrack?.albumImageUrl ?? DefaultAlbumArt}
          className={styles.NowPlayingAlbumArt}
        />
      </div>

      <div className={styles.NowPlayingTrackDetails}>
        <Label
          value={player?.currentTrack?.name || 'Unknown'}
          size={LabelSize.Small}
        />
        <Label
          value={player?.currentTrack?.albumName || 'Unknown'}
          size={LabelSize.Small}
          className={styles.NowPlayingTrackDetailsText}
        />
        <Label
          value={player?.currentTrack?.artists.join(', ') || 'Unknown'}
          size={LabelSize.Small}
          className={styles.NowPlayingTrackDetailsText}
        />

        <br />
        <input
          type="range"
          value={progressMs}
          step="1"
          min="0"
          max={trackLength}
          className="progress"
          onChange={e => scrubProgressMs(Number(e.target.value))}
          style={{ background: trackStyling }}
          // onMouseUp={togglePlay}
          // onKeyUp={togglePlay}
          disabled={disableControls}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>
            {Math.floor(progressMs / 1000 / 60)}:{progressSeconds % 60}
          </span>
          <span>
            {Math.floor(trackLength / 1000 / 60)}:{durationSeconds % 60}
          </span>
        </div>

        <div className={styles.NowPlayingControls}>
          <IconSVG
            name={SVGName.MusicPlayerPrevious}
            size={SVGSize.Small}
            className={styles.MusicPlayerControl}
            handleClick={async () =>
              player
                ? await player.previousTrack()
                : alert('player not available')
            }
            disabled={disableControls}
          />
          <IconSVG
            name={
              player.isPaused
                ? SVGName.MusicPlayerPlay
                : SVGName.MusicPlayerPause
            }
            size={SVGSize.Small}
            className={styles.MusicPlayerControl}
            handleClick={async () => {
              if (!player) return alert('player not available');
              try {
                await player.togglePlay();
              } catch (error) {
                console.log(error);

                notify({
                  source: 'Player',
                  title: 'Something went wrong',
                  body:
                    'There was an error toggling the audio in your player, please try again.',
                });
              }
            }}
            disabled={disableControls}
          />
          <IconSVG
            name={SVGName.MusicPlayerNext}
            size={SVGSize.Small}
            className={styles.MusicPlayerControl}
            handleClick={async () =>
              player ? await player.nextTrack() : alert('player not available')
            }
            disabled={disableControls}
          />
        </div>
      </div>
    </div>
  );
}
