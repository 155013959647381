import { useEffect } from 'react';

export interface CustomHeaderPageProps {
  setCustomNavBarHeader: (customHeader?: JSX.Element) => void;
}

export function useCustomHeaderEffect(
  setCustomNavBarHeader: (customHeader?: JSX.Element) => void,
  customHeader: JSX.Element,
  watchList?: any[]
) {
  useEffect(() => {
    setCustomNavBarHeader(customHeader);
    return () => {
      setCustomNavBarHeader();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, watchList || []);
  return null;
}
