import Label, { LabelSize } from '../../../components/typography/Label';
import Icon, { IconName } from '../../../components/foundation/Icon';

import Card, { Layout } from '../../../components/foundation/Card';

import styles from './index.module.scss';

const artists = [
  { name: 'Logic' },
  { name: 'SG Lewis' },
  { name: 'Drake' },
  { name: 'Eminem' },
  { name: 'Jaden' },
];

export default function Artists() {
  return (
    <div>
      {artists.map(pl => (
        <Card layout={Layout.Horizontal}>
          <div className={styles.ArtistImageWrapper}>
            <Icon
              name={IconName.SampleAlbumArt}
              className={styles.ArtistImage}
            />
          </div>
          <div className={styles.ArtistDetails}>
            <Label
              value={pl.name}
              size={LabelSize.Small}
              className={styles.ModeLabel}
            />
          </div>
        </Card>
      ))}
    </div>
  );
}
