import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as Models from 'disco-music-api';
import queryString from 'query-string';
import { usePlayer } from '../../../context/player';
import { useQuery } from '../../../hooks/useQuery';

export default function SpotifyCallback() {
  const [player] = usePlayer();
  const query = useQuery();
  const [success, setSuccess] = useState(false);

  const code = query.get('code');
  const state = query.get('state');

  useEffect(() => {
    if (!code || !state) return;

    // handle spotify auth callback flow
    const work = async () => {
      try {
        console.log('state.1', state);

        await player?.authorizeCallback(
          Models.Synthetic.Services.Service.Spotify,
          code,
          state
        );
        console.log('state.2', state);
        setSuccess(true);
      } catch (err) {
        console.log(`failed to authorize callback with err=${err}`);
        window.location.assign(
          `/settings?${queryString.stringify({
            setting: 'Services',
            message: 'Something went wrong authenticating to spotify!',
          })}`
        );
      }
    };
    work();
  }, [code, state]);
  return success ? (
    <Redirect
      to={{
        pathname: '/settings',
        search: `?${queryString.stringify({
          setting: 'Services',
          message: 'Successfully authenticated with spotify!',
        })}`,
      }}
    />
  ) : (
    <></>
  );
}
