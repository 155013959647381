import List from '../foundation/List';
import { MediaListItem } from './MediaListItem';

import styles from './index.module.scss';

export interface MediaItem {
  id: string;
  imageUrl: string;
  name: string;
  subTexts: string[];
}

interface Props<T> {
  mediaItems: T[];
  onClick?: (trackId: string) => Promise<void>;
}
export function MediaList<T extends MediaItem>({
  mediaItems,
  onClick,
}: Props<T>) {
  const itemsById: { [id: string]: T } = mediaItems.reduce(
    (all, item) => ({ ...all, [item.id]: item }),
    {}
  );
  return (
    <List
      items={Object.keys(itemsById)}
      renderCustomItem={id => {
        const item = itemsById[id];
        if (!item) return <></>;
        return (
          <MediaListItem
            key={id}
            imageUrl={item.imageUrl}
            name={item.name}
            subTexts={item.subTexts}
          />
        );
      }}
      onClick={onClick}
      className={styles.TrackList}
    />
  );
}
