import { useState, useEffect } from 'react';

import DiscoMusicAPI from 'disco-music-api';

// hook that manages a Spotify Connect SDK client given an access token
export default function useSpotifyClient(
  token: string | null
):
  | [
      Spotify.Player,
      {
        isPaused: boolean;
        isActive: boolean;
        currentTrack: Spotify.Track | null;
        playingNextTracks: Spotify.Track[];
        refreshDevices: () => void;
        currentDevice: any;
        otherDevices: any[];
        positionMs: number;
      }
    ]
  | null {
  const [player, setPlayer] = useState<Spotify.Player | null>(null);

  const [isPaused, setIsPaused] = useState(true);
  const [isActive, setActive] = useState(false);
  const [deviceId, setDeviceId] = useState<any | null>(null);
  const [currentDevice, setCurrentDevice] = useState<any | null>(null);
  const [otherDevices, setOtherDevices] = useState<any[]>([]);
  const [positionMs, setPositionMs] = useState<number>(0);
  const [currentTrack, setTrack] = useState<Spotify.Track | null>(null);
  const [playingNextTracks, setPlayingNextTracks] = useState<Spotify.Track[]>(
    []
  );

  useEffect(() => {
    if (!token) return;

    const script = document.createElement('script');
    script.src = 'https://sdk.scdn.co/spotify-player.js';
    script.async = true;

    document.body.appendChild(script);

    window.onSpotifyWebPlaybackSDKReady = () => {
      const spotifyPlayer = new window.Spotify.Player({
        name: 'DisCo Music',
        getOAuthToken: cb => {
          cb(token);
        },
        volume: 0.75,
      });

      spotifyPlayer.on('initialization_error', ({ message }) => {
        console.error('Failed to initialize', message);
      });

      spotifyPlayer.on('authentication_error', ({ message }) => {
        console.error('Failed to authenticate', message);
      });

      spotifyPlayer.on('account_error', ({ message }) => {
        console.error('Failed to validate Spotify account', message);
        alert('Non premium spotify users cannot use this service yet');
      });

      spotifyPlayer.on('playback_error', ({ message }) => {
        console.error('Failed to perform playback', message);
      });

      spotifyPlayer.addListener('ready', async ({ device_id }) => {
        console.log('Spotify is ready with Device ID', device_id);
        try {
          await DiscoMusicAPI.Player.transferPlayback(device_id);
        } catch (err) {
          console.error(
            `something went wrong transferring playback on spotifySDK init with err: ${err}`
          );
        }
        setDeviceId(device_id);
        setPlayer(spotifyPlayer);
      });

      spotifyPlayer.addListener('not_ready', ({ device_id }) => {
        console.log('Device ID has gone offline', device_id);
        setPlayer(null);
      });

      spotifyPlayer.addListener('player_state_changed', state => {
        console.log(`player_state_changed:`);
        console.log(state);

        if (!state) {
          return;
        }

        setPlayingNextTracks(state.track_window.next_tracks);
        setTrack(state.track_window.current_track);
        // TODO update player (parent) to useEffect whenever these values change to set player's state
        // NOTE: this currently only applies to Spotify which allows controlling the player from another device
        // authenticated with Spotify
        // console.log('isSpotifyPaused:', state.paused);

        setIsPaused(state.paused);
        setPositionMs(state.position);
        // state.repeat_mode
        // state.shuffle

        spotifyPlayer.getCurrentState().then(state => {
          !state ? setActive(false) : setActive(true);
        });
      });

      spotifyPlayer.connect();
    };
    return () => {
      player?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getDevices = async () => {
    const {
      devices: spotifyDevices,
    } = (await DiscoMusicAPI.Services.Spotify.getDevices()) as any;

    setCurrentDevice(
      (spotifyDevices as any[]).find(({ id }) => id === deviceId)
    );

    setOtherDevices(
      (spotifyDevices as any[]).filter(({ id }) => id !== deviceId)
    );
  };

  useEffect(() => {
    if (!player) return;
    const init = async () => {
      await getDevices();
      // await player.togglePlay();
      // await DiscoMusicAPI.Player.transferPlayback(deviceId);
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  // Return the user object and auth methods
  return player
    ? [
        player,
        {
          isPaused,
          isActive,
          currentTrack,
          playingNextTracks,
          refreshDevices: getDevices,
          currentDevice,
          otherDevices,
          positionMs,
        },
      ]
    : null;
}
