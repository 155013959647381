export default function MusicPlayerPlay() {
  return (
    <path
      stroke="none"
      fill="#fff"
      transform="rotate(90 62.5 62.5)"
      d="M57.901923788647 9.3734122634726a3 3 0 0 1 5.1961524227066 0l57.303847577293 99.253175473055a3 3 0 0 1 -2.5980762113533 4.5l-114.60769515459 0a3 3 0 0 1 -2.5980762113533 -4.5"
    ></path>
  );
}
