import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { useHistory, Link } from 'react-router-dom';
import * as Models from 'disco-music-api';

import * as Auth from '../../context/auth';
import Alert from '../../components/foundation/Alert';
import Button from '../../components/foundation/Button';

import 'react-phone-number-input/style.css';
import styles from './index.module.scss';

export default function Register() {
  const [username, setUsername] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [inviteCode, setInviteCode] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const authContext = Auth.useAuth();
  let history = useHistory();
  // let location = useLocation<{ referrer: string }>();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!username) {
      setValidationErrors(['Invalid username']);
      return;
    }

    if (!phoneNumber) {
      setValidationErrors(['Invalid phone number']);
      return;
    }

    if (!inviteCode) {
      setValidationErrors(['Invalid invite code']);
      return;
    }

    try {
      await authContext.register(username, phoneNumber, inviteCode);
      await authContext.requestVerification(
        username,
        Models.Synthetic.Auth.VerificationMethod.SMS
      );
      history.replace(`/verify/${username}`);
    } catch (error) {
      console.error(error);
      setValidationErrors([error.message]);
      return;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.Content}>
        <h2>Register</h2>
        <p>Tell us a bit about yourself. We just need the basics.</p>

        <form onSubmit={handleSubmit}>
          {validationErrors.length ? (
            <Alert>
              <>
                {validationErrors.map((v, i) => (
                  <b key={i}>{v}</b>
                ))}
              </>
            </Alert>
          ) : null}

          <label>Username:</label>
          <input
            placeholder="Enter a username"
            type={'text'}
            value={username || ''}
            onChange={e => setUsername(e.target.value)}
            className={styles.RegistrationInput}
          />

          <label>Phone Number:</label>
          <PhoneInput
            placeholder="Enter phone number"
            defaultCountry="US" // TODO try and localize this
            countryOptionsOrder={['US', 'CA', '|', '...']}
            // useNationalFormatForDefaultCountryValue={true}
            value={phoneNumber || ''}
            onChange={setPhoneNumber}
            className={`${styles.PhoneStyles} ${styles.RegistrationInput}`}
          />

          <label>Invite Code:</label>
          <input
            placeholder="Enter an invite code"
            type={'text'}
            value={inviteCode || ''}
            onChange={e => setInviteCode(e.target.value)}
            className={styles.RegistrationInput}
          />

          <br />

          <div className={styles.Footer}>
            <Button name={'Register'} type={'submit'} />
            <span>
              Have an account? <Link to={'/login'}>Login</Link>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}
