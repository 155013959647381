import { useRef, useState, useEffect, MutableRefObject } from 'react';
import styles from './index.module.scss';

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-segmented-control-component
 */
interface Props<T> {
  name: string;
  segments: {
    label: string;
    value: T;
    ref: MutableRefObject<any>;
  }[];
  callback: (value: T, index: number) => void;
  defaultIndex?: number;
  controlRef: MutableRefObject<any>;
}
export default function SegmentedControl<T>({
  name,
  segments,
  callback,
  defaultIndex,
  controlRef,
}: Props<T>) {
  const [activeIndex, setActiveIndex] = useState(defaultIndex || 0);
  const componentReady = useRef(false);

  // Determine when the component is "ready"
  useEffect(() => {
    componentReady.current = true;
  }, []);

  useEffect(() => {
    const activeSegmentRef = segments[activeIndex].ref;
    const { offsetWidth, offsetLeft } = activeSegmentRef.current;
    const { style } = controlRef.current;

    style.setProperty('--highlight-width', `${offsetWidth}px`);
    style.setProperty('--highlight-x-pos', `${offsetLeft}px`);
  }, [activeIndex, callback, controlRef, segments]);

  const onInputChange = (value: T, index: number) => {
    setActiveIndex(index);
    callback(value, index);
  };

  return (
    <div className={styles.ControlsContainer} ref={controlRef}>
      <div
        className={`${styles.Controls} ${
          componentReady.current ? styles.Ready : ''
        }`}
      >
        {segments?.map((item, i) => (
          <div
            key={`${item.value}`}
            className={`${styles.Segment} ${
              i === activeIndex ? styles.Active : ''
            }`}
            ref={item.ref}
          >
            <input
              type="radio"
              value={`${item.value}`}
              id={item.label}
              name={name}
              onChange={() => onInputChange(item.value, i)}
              checked={i === activeIndex}
            />
            <label htmlFor={item.label}>{item.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
}
