export default function PlayerRepeatAll() {
  return (
    <g
      transform="translate(15.000000,105.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M600 735 l0 -45 -206 0 c-231 0 -254 -5 -301 -67 -33 -43 -45 -136
-19 -146 28 -11 44 3 55 47 12 49 44 83 92 96 19 6 112 10 206 10 l173 0 0
-45 c0 -25 3 -45 6 -45 13 0 204 113 204 120 0 7 -191 120 -204 120 -3 0 -6
-20 -6 -45z"
      />
      <path
        d="M792 421 c-5 -3 -15 -24 -21 -47 -13 -48 -45 -81 -92 -94 -19 -6
-112 -10 -206 -10 l-173 0 0 45 c0 25 -3 45 -6 45 -13 0 -204 -113 -204 -120
0 -7 191 -120 204 -120 3 0 6 20 6 45 l0 45 206 0 c231 0 254 5 301 67 19 24
28 50 31 87 4 47 2 54 -16 58 -11 3 -24 3 -30 -1z"
      />
    </g>
  );
}
