import styles from './index.module.scss';

interface Props {
  children: any;
}
export function Gutter({ children }: Props) {
  return (
    <div className={styles.Gutter}>
      <div className={styles.GutterList}>{children}</div>
    </div>
  );
}
