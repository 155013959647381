import styles from './index.module.scss';

interface Props {
  handleClick: () => Promise<void>;
  isChecked: boolean;
}

export default function Toggle(props: Props) {
  const { handleClick, isChecked } = props;

  return (
    <input
      className={styles.Toggle}
      type="checkbox"
      checked={isChecked}
      onClick={handleClick}
    />
  );
}
