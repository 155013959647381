import Label, { LabelSize } from '../../../components/typography/Label';
import Icon, { IconName } from '../../../components/foundation/Icon';

import Card, { Layout } from '../../../components/foundation/Card';

import styles from './index.module.scss';

const albums = [
  { name: 'No Pressure', artist: 'Logic' },
  { name: 'Sunsets', artist: 'SG Lewis' },
  { name: 'Back To Back', artist: 'Drake' },
  { name: 'Recovery', artist: 'Eminem' },
  { name: 'SYRE', artist: 'Jaden' },
];

export default function Albums() {
  return (
    <div className={styles.Albums}>
      {albums.map(pl => (
        <Card layout={Layout.Horizontal}>
          <div className={styles.ArtistImageWrapper}>
            <Icon
              name={IconName.SampleAlbumArt}
              className={styles.ArtistImage}
            />
          </div>
          <div className={styles.ArtistDetails}>
            <Label
              value={pl.name}
              size={LabelSize.Small}
              className={styles.ModeLabel}
            />
            <Label
              value={pl.artist}
              size={LabelSize.Small}
              className={styles.ModeLabel}
            />
          </div>
        </Card>
      ))}
    </div>
  );
}
