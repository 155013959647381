import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import * as Auth from '../../context/auth';
import IconSVG, { SVGName, SVGSize } from '../foundation/IconSVG';
import Alert from '../foundation/Alert';
import { CustomHeaderPageProps } from '../../pages';

import styles from './index.module.scss';

interface Props {
  children: React.FC<CustomHeaderPageProps>;
  onToggleSideBar: () => void;
}

export default function NavBar(props: Props) {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [customHeader, setCustomHeader] = useState<JSX.Element | null>(null);

  const authContext = Auth.useAuth();

  const handleLogout = () => {
    try {
      authContext.logout();
    } catch (error) {
      setValidationErrors([error.message]);
      return;
    }
  };

  /* Smaller than phablet (also point before grid becomes active) */
  const isMobile = useMediaQuery({
    query: '(max-width: 550px)',
  });
  const { children: PageComponent, onToggleSideBar } = props;
  return (
    <div className={`${styles.six} ${styles.columns} ${styles.NavBarWithBody}`}>
      <div className={styles.NavBar}>
        {/* TODO this isnt vetically aligned... */}
        {/* TODO move this into the customHEader */}
        {isMobile && (
          <IconSVG
            name={SVGName.NavBarMenu}
            size={SVGSize.Medium}
            handleClick={onToggleSideBar}
            gradient
          />
        )}

        {customHeader}

        <div className={styles.NavBarLogout}>
          {authContext.user && <span onClick={handleLogout}>Logout</span>}
        </div>
        {validationErrors.length ? (
          <Alert>
            <>
              {validationErrors.map((v, i) => (
                <b key={i}>{v}</b>
              ))}
            </>
          </Alert>
        ) : null}
      </div>

      <PageComponent
        setCustomNavBarHeader={customHeader =>
          setCustomHeader(customHeader || null)
        }
      />
    </div>
  );
}
