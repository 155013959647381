import React from 'react';

import styles from './index.module.scss';

interface Props {
  name?: string | JSX.Element;
  children?: JSX.Element;
  icon?: JSX.Element;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function Button(props: Props) {
  const { name, children, icon, className, type, handleClick } = props;

  if (children && (name || icon))
    throw new Error(
      `Either props 'children' OR 'name' and/or 'icon' must be provided.`
    );
  if (!children && !name && !icon)
    throw new Error(
      `Missing props 'children' or 'name' and/or 'icon' must be provided.`
    );

  const defaultValue = name ? (
    <span className={styles.ButtonText}>{name}</span>
  ) : null;
  return (
    <button className={`${className || ''}`} onClick={handleClick} type={type}>
      {children ?? icon ? (
        <span className={styles.Wrapper}>
          {icon}
          {defaultValue}
        </span>
      ) : (
        defaultValue
      )}
    </button>
  );
}
