import Icon, { IconName } from '../../foundation/Icon';
import Label, { LabelSize } from '../../typography/Label';

import styles from './index.module.scss';

interface Props {
  imageUrl: string;
  name: string;
  subTexts: string[];
  disableHover?: boolean;
}

export function MediaListItem({
  imageUrl,
  name,
  subTexts,
  disableHover,
}: Props) {
  return (
    <div className={`${styles.Track} ${disableHover || styles.TrackHover}`}>
      <div className={styles.TrackAlbumArtWrapper}>
        <Icon
          name={IconName.SampleAlbumArt}
          url={imageUrl}
          className={styles.TrackAlbumArt}
        />
      </div>
      <div className={styles.TrackDetails}>
        <Label value={name} size={LabelSize.Small} />
        <Label value={subTexts.join(', ')} size={LabelSize.ExtraSmall} />
      </div>
    </div>
  );
}
