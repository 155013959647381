import DiscoMusicAPI from 'disco-music-api';
import * as Models from 'disco-music-api';

import DefaultAlbumArt from '../../assets/default_album_art.png';
import Label, { LabelSize } from '../../components/typography/Label';
import Button from '../../components/foundation/Button';
import IconSVG, { SVGName, SVGSize } from '../../components/foundation/IconSVG';

import styles from './index.module.scss';
import React, { useEffect, useState } from 'react';
import { TrackListItem } from '../../components/TrackList/TrackListItem';
import { usePlayer } from '../../context/player';
import { useAuth } from '../../context/auth';

const MESSAGES_OF_THE_DAY = ['Good evening', 'Welcome', 'Good morning'];

export default function PartiesPage() {
  const [player] = usePlayer();
  const { user } = useAuth();
  const [position, setPosition] = useState<GeolocationPosition | null>(null);
  const [refresh, setRefresh] = useState(false);
  // const [parties, setParties] = useState<Models.Player.PartyPlayer[]>([]);
  // const [party, setParty] = useState<Models.Party.PartyState | null>(null);

  // useEffect(() => {
  //   window.navigator.geolocation.getCurrentPosition(
  //     async pos => {
  //       console.log('pos', pos);
  //       setPosition(pos);

  //       const response = await DiscoMusicAPI.Player.getParties();
  //       console.log(parties);

  //       setParties(response.parties);
  //     },
  //     async err => {
  //       console.log(err);
  //       const response = await DiscoMusicAPI.Player.getParties();
  //       console.log(parties);

  //       setParties(response.parties);
  //     }
  //   );

  //   const getParty = async () => {
  //     const { party } = await DiscoMusicAPI.Player.getParty();
  //     setParty(party);
  //   };
  //   getParty();
  // }, [refresh]);
  const party = player?.currentParty;
  return (
    <div className={styles.PartiesPage}>
      {/* <div className={styles.T}> */}
      {/* <h2>{MESSAGES_OF_THE_DAY[_getRandomInt(0, 1)]}</h2> */}
      <h2>{MESSAGES_OF_THE_DAY[0]}</h2>

      <div className={styles.MyPartiesSection}>
        {party ? (
          <PartyTile
            hostUsername={party.hostUsername}
            partyId={party.id}
            isHost={!user ? false : user.id === party.hostUserId}
            nowPlayingTrackAlbumImageUrl={party.nowPlayingTrackAlbumImageUrl}
            nowPlayingTrackArtists={party.nowPlayingTrackArtists}
            nowPlayingTrackName={party.nowPlayingTrackName}
            activePartyId={player?.partyId}
          />
        ) : (
          <Button
            name={'Start a party'}
            icon={
              <IconSVG
                name={SVGName.PartyDrink}
                className={styles.StartPartyButtonIcon}
                size={SVGSize.Small}
                gradient
              />
            }
            handleClick={() => alert('Create Party path coming soon')}
          />
        )}
      </div>

      <Label
        value={'Nearby Parties'}
        size={LabelSize.Large}
        className={styles.ModeLabel}
      />
      {player?.parties.length ? (
        player.parties.map(partyPlayer => {
          return (
            <PartyTile
              hostUsername={partyPlayer.hostUsername}
              partyId={partyPlayer.partyId!}
              isHost={false}
              nowPlayingTrackAlbumImageUrl={
                partyPlayer.nowPlayingTrackAlbumImageUrl
              }
              nowPlayingTrackArtists={partyPlayer.nowPlayingTrackArtists}
              nowPlayingTrackName={partyPlayer.nowPlayingTrackName}
              activePartyId={player?.partyId}
            />
          );
        })
      ) : (
        <React.Fragment>
          <h4>No Parties nearby</h4>
          <Button
            name={'Refresh'}
            handleClick={async () => await player?.refreshParties()}
          />
        </React.Fragment>
      )}
      {/* </div> */}
    </div>
  );
}

interface PartyTileProps {
  hostUsername: string;
  partyId: string;
  isHost: boolean;
  nowPlayingTrackAlbumImageUrl?: string;
  nowPlayingTrackName?: string;
  nowPlayingTrackArtists?: string[];
  activePartyId?: string;
}
function PartyTile({
  hostUsername,
  partyId,
  isHost,
  nowPlayingTrackAlbumImageUrl,
  nowPlayingTrackName,
  nowPlayingTrackArtists,
  activePartyId,
}: PartyTileProps) {
  const [player] = usePlayer();
  return (
    <div className={styles.Party}>
      <Label
        value={`@${hostUsername}'s Party`}
        size={LabelSize.Small}
        className={styles.ModeLabel}
      />

      <TrackListItem
        key={partyId}
        albumImageUrl={nowPlayingTrackAlbumImageUrl || DefaultAlbumArt}
        name={nowPlayingTrackName || 'Not Playing'}
        artists={nowPlayingTrackArtists || []}
        disableHover
      />

      <br />

      {activePartyId !== partyId ? (
        <Button
          name={'Join'}
          handleClick={async () => {
            await player?.joinParty(partyId);
          }}
        />
      ) : isHost ? (
        <Button
          name={'End'}
          handleClick={async () => {
            await player?.toggleParty();
          }}
        />
      ) : (
        <Button
          name={'Leave'}
          handleClick={async () => {
            await player?.leaveParty();
          }}
        />
      )}
    </div>
  );
}

function _getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
