import { useState, useEffect, useContext, createContext, useMemo } from 'react';
// import Cookies from 'js-cookie';
import DiscoMusicAPI from 'disco-music-api';
import * as Models from 'disco-music-api';
import usePolling from '../effects/polling';

export interface DevicesContext {
  refreshing: boolean;
  activeDevice: Models.Device.Device | null;
  otherDevices: Models.Device.Device[];
  requestingDeviceDetails: Models.Device.DeviceDetails;
  refresh: () => void;
}

const DEFAULT_DEVICE_DETAILS: Models.Device.DeviceDetails = {
  clientName: null,
  osName: null,
  deviceType: null,
  deviceModel: null,
  deviceBrand: null,
};

const INITIAL_DEVICES_CONTEXT_VALUE: DevicesContext = {
  refreshing: true,
  activeDevice: null,
  otherDevices: [],
  requestingDeviceDetails: DEFAULT_DEVICE_DETAILS,
  refresh: () => void 0,
};
const devicesContext = createContext<DevicesContext>(
  INITIAL_DEVICES_CONTEXT_VALUE
);

export interface ProvideDevicesProps {
  children: any;
}

export default function ProvideDevices({ children }: ProvideDevicesProps) {
  const devices = useProvideDevices();
  return (
    <devicesContext.Provider value={devices}>
      {children}
    </devicesContext.Provider>
  );
}

export const useDevices = (): DevicesContext => {
  return useContext(devicesContext);
};

function useProvideDevices(): DevicesContext {
  const [refreshing, setRefreshing] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [activeDevice, setActiveDevice] = useState<Models.Device.Device | null>(
    null
  );
  const [otherDevices, setOtherDevices] = useState<Models.Device.Device[]>([]);
  const [
    requestingDeviceDetails,
    setRequestingDeviceDetails,
  ] = useState<Models.Device.DeviceDetails>(DEFAULT_DEVICE_DETAILS);

  const fetchDevicesState = async () => {
    setRefreshing(true);
    try {
      const response = await DiscoMusicAPI.Devices.summary();
      if (response.activeDevice) setActiveDevice(response.activeDevice);
      if (response.otherDevices) setOtherDevices(response.otherDevices);
      if (response.requestingDeviceDetails)
        setRequestingDeviceDetails(response.requestingDeviceDetails);
    } catch (e) {
      console.error(e);
    } finally {
      setRefreshing(false);
    }
  };

  // usePolling(fetchDevicesState, 10000);

  useEffect(() => {
    fetchDevicesState();
    return () => {
      setActiveDevice(null);
      setOtherDevices([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return {
    refreshing,
    activeDevice,
    otherDevices,
    requestingDeviceDetails,
    refresh: () => setRefresh(!refresh),
  };
}
