import { useEffect } from 'react';
import { useDevices } from '../../../../context/devices';
import { usePlayer } from '../../../../context/player';
import Button from '../../../foundation/Button';
import Modal from '../../../foundation/Modal';

export interface Props {
  onToggle: () => void;
}
export default function DevicesModal({ onToggle }: Props) {
  const [musicPlayer] = usePlayer();

  const currentDevice = musicPlayer?.currentDevice;
  const otherDevices = musicPlayer?.otherDevices || [];

  useEffect(() => {
    const loadDevices = async () => {
      musicPlayer?.refreshDevices();
    };
    loadDevices();
  }, []);

  return (
    <Modal onToggle={async () => onToggle()}>
      <>
        <h1>Current Device</h1>
        <span>
          {currentDevice
            ? `${currentDevice.name || ''} on ${
                currentDevice.type || ''
              }`.trim()
            : 'None'}
        </span>

        <div style={{ padding: '1rem' }} />

        <h1>Select A Device</h1>
        {otherDevices.map(device => (
          <span>{`${device.name || ''} on ${device.type || ''}`.trim()}</span>
        ))}

        <div style={{ padding: '1rem' }} />

        <Button
          name={'Switch Devices'}
          handleClick={async event => {
            event.preventDefault();
            //   await auth.refresh();
            // let { referrer } = location.state || { referrer: '/parties' };
            // history.replace('/parties');
          }}
        />
      </>
    </Modal>
  );
}
