export default function PlayerShuffle() {
  return (
    <g
      transform="translate(15.000000,105.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M630 705 l0 -45 -63 0 c-80 0 -122 -22 -167 -88 -19 -28 -37 -51 -40
-51 -3 0 -21 23 -40 51 -48 70 -85 88 -181 88 -59 0 -78 -3 -83 -15 -12 -31 4
-42 70 -48 35 -2 75 -12 89 -21 31 -20 95 -105 95 -126 0 -21 -64 -106 -95
-126 -14 -9 -54 -19 -89 -21 -66 -6 -82 -17 -70 -48 5 -12 24 -15 83 -15 96 0
133 18 181 88 19 28 37 51 40 51 3 0 21 -23 40 -51 45 -66 87 -88 167 -88 l63
0 0 -45 c0 -25 2 -45 5 -45 10 0 175 113 175 120 0 7 -165 120 -175 120 -3 0
-5 -21 -5 -46 l0 -47 -51 6 c-28 4 -62 13 -75 22 -30 19 -94 105 -94 125 0 20
64 106 94 125 13 9 47 18 75 22 l51 6 0 -47 c0 -25 2 -46 5 -46 10 0 175 113
175 120 0 7 -165 120 -175 120 -3 0 -5 -20 -5 -45z"
      />
    </g>
  );
}
